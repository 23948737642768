import "assets/css/common.css";
import "assets/css/material-dashboard-react.css?v=1.5.0";
import { init as initCache } from "utils/cache/cacheProvider";
import "react-app-polyfill/stable";
import { createRoot } from "react-dom/client";
import "moment/locale/pl";
import App from "./App";
import { unregister } from "./registerServiceWorker";

const container = document.getElementById("root");
const root = createRoot(container);

initCache();
unregister();

root.render(<App />);
