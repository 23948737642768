import TextWithLabel from "components/Text/TextWithLabel";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import { useCommonTranslation } from "utils/hooks";
import React from "react";
import { Grid } from "@mui/material";

const field = (label, value, format, options) => {
    return {
        label,
        value,
        format,
        options,
    };
};

const PromotionActivation = ({ activationInfo = {} }) => {
    const { activationCount, lastActivatedAt } = activationInfo;
    const { t, common } = useCommonTranslation();
    const info = [
        field(t(common.activationCount), activationCount, formatTypes.text),
        field(t(common.lastActivatedAt), lastActivatedAt, formatTypes.time, {
            timeFormat: timePrecisonFormats.seconds,
        }),
    ];

    return info.map((f, i) => (
        <Grid
            item
            key={i}
        >
            <TextWithLabel
                unselectableLabel
                key={f.label}
                {...f}
            />
        </Grid>
    ));
};

export default PromotionActivation;
