import { ArrayPath, FieldArray, FieldValues, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { Grid } from "@mui/material";
import { FormListControlProps } from "./types";
import { exportAsJson } from "utils-ts/functions";
import { List } from "components-ts/controls";
import FormArrayHelperText from "components-ts/controls/inputs/FormArrayHelperText";
import { Spacing, View } from "components-ts/view";
import { FormPrefixProvider } from "../contexts";
import { usePrefixContext } from "../contexts";
import { GridHeader } from "../grid";
import { FormColumn } from "../layout";

const FormList = <T extends {}, TFieldValues extends FieldValues = FieldValues, TName extends ArrayPath<TFieldValues> = ArrayPath<TFieldValues>>({
    name,
    canAddItem = true,
    canRemoveItem,
    defaultValue,
    children,
    label,
    viewType,
    iconName,
    hideDivider = false,
    useCollapse = false,
    isCollapsed = false,
    type,
    canCopyItem = false,
    canExportItem = false,
    exportPartItem,
    exportPartLabel,
}: FormListControlProps<T, TFieldValues, TName>): JSX.Element => {
    const prefix = usePrefixContext();
    const key = prefix ? `${prefix}.${name}` : name;
    const { fields, append, remove, move } = useFieldArray<TFieldValues, TName>({
        name: key as TName,
    });
    const { getValues } = useFormContext();
    const val = getValues(key as TName);
    const exportPartItemProps =
        exportPartItem && exportPartLabel
            ? {
                  exportPartItem: (index: number) => exportPartItem(val[index] as T, index),
                  exportPartLabel: exportPartLabel,
              }
            : {
                  exportPartItem: undefined,
                  exportPartLabel: undefined,
              };

    const listType =
        type === "draggable"
            ? {
                  type,
                  moveItem: (fromIndex: number, toIndex: number) => {
                      console.log({ fromIndex, toIndex });
                      move(fromIndex, toIndex);
                  },
              }
            : {
                  type,
                  moveItem: undefined,
              };

    const list = (
        <>
            <FormArrayHelperText name={key} />
            <List
                addItem={canAddItem ? () => append({ index: (val || []).length, ...defaultValue } as FieldArray<TFieldValues, TName>) : undefined}
                removeItem={(index) => {
                    remove(index);
                }}
                canRemoveItem={canRemoveItem}
                hideDivider={hideDivider}
                copyItem={canCopyItem ? (index) => append({ index: fields.length, ...val[index] }) : undefined}
                exportItem={
                    canExportItem
                        ? (index) => {
                              exportAsJson(val[index], `${name}_${index}`);
                          }
                        : undefined
                }
                {...listType}
                {...exportPartItemProps}
            >
                {fields.map((item, index) => {
                    return (
                        <FormPrefixProvider
                            name={`${name}.${index}`}
                            key={item.id}
                        >
                            <FormColumn>{children({ item: item as T, index, key: item.id })}</FormColumn>
                        </FormPrefixProvider>
                    );
                })}
            </List>
        </>
    );

    if (label) {
        if (viewType === "GridContainer") {
            return (
                <Grid
                    item
                    xs={12}
                    style={{ width: "100%" }}
                >
                    <Spacing spacing={1}>
                        <GridHeader
                            title={label}
                            iconName={iconName}
                        />
                        {list}
                    </Spacing>
                </Grid>
            );
        } else {
            return (
                <Grid
                    item
                    xs={12}
                    style={{ width: "100%" }}
                >
                    <View
                        headerText={label}
                        useCollapse={useCollapse}
                        isCollapsed={isCollapsed}
                    >
                        <Spacing spacing={1}>{list}</Spacing>
                    </View>
                </Grid>
            );
        }
    } else {
        return (
            <Grid
                item
                xs={12}
                style={{ width: "100%" }}
            >
                {list}
            </Grid>
        );
    }
};

export default FormList;
