import { RSAA } from "redux-api-middleware";
import { startSubmit, stopSubmit } from "redux-form";

export const PRODUCTS_GET_PAGE = "@@content/PRODUCTS_GET_PAGE";
export const PRODUCTS_GET_PAGE_SUCCESS = "@@content/PRODUCTS_GET_PAGE_SUCCESS";
export const PRODUCTS_GET_PAGE_FAILURE = "@@content/PRODUCTS_GET_PAGE_FAILURE";
export const UPDATE_PRODUCTS_FILTER = "UPDATE_PRODUCTS_FILTER";

export const updateProductsFilter = (filterValues) => ({
    type: UPDATE_PRODUCTS_FILTER,
    payload: filterValues,
});

export const getProducts = (
    pageIndex = 1,
    pageSize = 10,
    productIds = null,
    search = null,
    name = null,
    brand = null,
    subbrand = null,
    producer = null,
    supplier = null,
    eans = null,
    filteredProductIds = null,
    filteredCategoryId = null
) => {
    return (dispatch) => {
        dispatch(startSubmit("FilterProductsForm"));
        return dispatch({
            [RSAA]: {
                endpoint: `api/v1/products`,
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify({
                    pageIndex: pageIndex,
                    pageSize,
                    productIds: productIds.filter((x) => x),
                    search,
                    name,
                    brand,
                    subbrand,
                    producer,
                    supplier,
                    eans: eans.filter((x) => x),
                    filteredCategoryId,
                    filteredProductIds,
                }),
                types: [
                    {
                        type: PRODUCTS_GET_PAGE,
                        payload: (action) => ({ endpoint: action.endpoint }),
                    },
                    {
                        type: PRODUCTS_GET_PAGE_SUCCESS,
                        payload: (action, state, res) => {
                            dispatch(stopSubmit("FilterProductsForm"));
                            const contentType = res.headers.get("Content-Type");
                            if (contentType && contentType.indexOf("json")) {
                                return res.json();
                            }
                            return res;
                        },
                    },
                    {
                        type: PRODUCTS_GET_PAGE_FAILURE,
                        payload: (action, state, res) => {
                            dispatch(stopSubmit("FilterProductsForm"));
                            return res;
                        },
                    },
                ],
            },
        });
    };
};
