import moment from "moment";
import { FindProductByEanModel, FindProductsModel, FoundProductResult } from "Content";
import { Fetcher } from "api-types";
import { useFileQuery, useGetPagedQuery, useGetQuery } from "api/hooks";

const queryKey = "api/v1/products/";

export const useGetProductSeoReport = (productIds: string[], createdFrom: Date | undefined, submit: boolean) => {
    return useFileQuery<Fetcher.FileResponse, {}, { productIds: string[]; createdFrom: Date | undefined }>({
        app: "content",
        url: "api/v1/products/seo-report",
        fileName: "Raport SEO",
        shouldMakeRequest: submit,
        method: "POST",
        body: { productIds, createdFrom },
    });
};

export const useFindProducts = (initailParams: FindProductsModel, isEnabled: boolean) => {
    return useGetPagedQuery<FoundProductResult, FindProductsModel>({
        app: "content",
        url: queryKey,
        method: "POST",
        body: initailParams,
        shouldMakeRequest: isEnabled,
    });
};

export const useFindProductByEan = (ean?: string) => {
    return useGetQuery<FindProductByEanModel>({
        app: "content",
        url: `${queryKey}ean/${ean}`,
        shouldMakeRequest: Boolean(ean),
    });
};
