import { formValueSelector } from "redux-form";
import { toDictionary } from "utils/extensions/arrayExtensions";
import { createSelector } from "reselect";
import PromotionModel, { GiftChoiceModel, GiftModel, RewardForm } from "views/offers/model";

const offerSelector = (state) => state.offers;

const feedSelector = createSelector(offerSelector, (offers) => offers.feed);

export const feedListSelector = createSelector(feedSelector, (feed) => feed.list);

const feedFormSelector = createSelector(feedSelector, (feed) => feed.form);

const queryParametersSelector = (form) => {
    const { queryParameters } = form;
    if (!queryParameters) return undefined;

    return {
        queryParameters: toDictionary(queryParameters),
    };
};

export const mappedFeedFormSelector = createSelector(feedFormSelector, (form) => ({
    ...form,
    ...queryParametersSelector(form),
}));

const contentSelector = createSelector(offerSelector, (offers) => offers.content);

export const contentListSelector = createSelector(contentSelector, (content) => content.list);

const contentFormSelector = createSelector(contentSelector, (content) => content.form);

const contentDataSelector = (form) => {
    const { contentData } = form;
    if (!contentData) return undefined;

    return {
        contentData: toDictionary(contentData),
    };
};

export const mappedContentFormSelector = createSelector(contentFormSelector, (form) => ({
    ...form,
    ...contentDataSelector(form),
}));

const discountsSelector = createSelector(offerSelector, (offers) => offers.discounts);

export const discountListSelector = createSelector(discountsSelector, (discount) => discount.list);

const discountFormSelector = createSelector(discountsSelector, (discount) => discount.form);

export const mappedDiscountFormSelector = createSelector(discountFormSelector, (form) => {
    const { products } = form;

    return {
        ...form,
        products: products
            ? products.map((x) => {
                  const { discountPercent, discountValue } = x.modifier;
                  const type = discountPercent ? "discountPercent" : discountValue ? "discountValue" : "newPrice";
                  return {
                      ...x,
                      type,
                  };
              })
            : undefined,
    };
});

const promotionsSelector = createSelector(offerSelector, (offers) => offers.promotions);

export const promotionsListSelector = createSelector(promotionsSelector, (promotions) => promotions.list);

export const promotionsFormSelector = createSelector(promotionsSelector, (promotions) => new PromotionModel(promotions.form));

const giftsSelector = createSelector(offerSelector, (offers) => offers.gifts);

export const giftsListSelector = createSelector(giftsSelector, (gifts) => gifts.list);

export const giftsFormSelector = createSelector(giftsSelector, (gifts) => new GiftModel(gifts.form));

const giftChoicesSelector = createSelector(offerSelector, (offers) => offers.giftChoices);

export const giftChoicesListSelector = createSelector(giftChoicesSelector, (gifts) => gifts.list);

export const giftChoicesFormSelector = createSelector(giftChoicesSelector, (gifts) => new GiftChoiceModel(gifts.form));

const contractsSelector = createSelector(offerSelector, (offers) => offers.contracts);

export const contractsListSelector = createSelector(contractsSelector, (contracts) => contracts.list);

const contractFormSelector = createSelector(contractsSelector, (contract) => contract.form);

export const mappedContractFormSelector = createSelector(contractFormSelector, (form) => {
    const { products, productPredicates } = form;

    return {
        ...form,
        products: products
            ? products.map((x) => {
                  const { discountPercent, discountValue } = x.modifier || {};
                  const type = discountPercent ? "discountPercent" : discountValue ? "discountValue" : "newPrice";
                  return {
                      ...x,
                      type,
                  };
              })
            : undefined,
        productPredicates: productPredicates
            ? productPredicates.map((x) => {
                  const { discountPercent, discountValue } = x.modifier || {};
                  const type = discountPercent ? "discountPercent" : discountValue ? "discountValue" : "newPrice";
                  return {
                      ...x,
                      type,
                  };
              })
            : undefined,
    };
});

const cacheSelector = (state) => state.cache;

export const cachedProductsSelector = createSelector(cacheSelector, (cache) => cache.products);

export const formProductsSelector = (formName, state) => formValueSelector(formName)(state, "products");
export const formProductPredicatesSelector = (formName, state) => formValueSelector(formName)(state, "productPredicates");
export const formUsersSelector = (formName, state) => formValueSelector(formName)(state, "users");

const productPreviewSelector = createSelector(offerSelector, (offer) => offer.productPreview);
export const productProductPreviewSelector = createSelector(productPreviewSelector, (productPreview) => productPreview.product);
export const productPriceHistorySelector = createSelector(productPreviewSelector, (productPreview) => productPreview.product.prices);

const rewardsSelector = createSelector(offerSelector, (offers) => offers.rewards);

export const rewardsListSelector = createSelector(rewardsSelector, (rewards) => rewards.list);

export const rewardsFormSelector = createSelector(rewardsSelector, (rewards) => new RewardForm(rewards.form));
