import { ContainerDocument, ContainerSection } from "Commerce-Content";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { api } from "api";
import { common } from "translations";
import { ProfileTypes } from "types/commerceEnums";
import { copyToClipboard, exportAsCsv } from "utils-ts/functions";
import { useForm, useTranslation, useValidator } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { Button } from "components-ts/controls";
import {
    Form,
    FormAutocomplete,
    FormDateTime,
    FormDivisionSelect,
    FormList,
    FormProductsDragAndDrop,
    FormRow,
    FormSelect,
    FormSwitch,
    FormTextField,
} from "components-ts/forms";
import { CartContextPredicate, ContentData } from "components-ts/forms/controls/commerce";
import { ContainerValidator } from "./validator";

const CmsForm: React.FC = () => {
    const { id, isCopy } = useParams<{ id?: string; isCopy?: string }>();
    const { t } = useTranslation();
    const { data, status } = api.commerce.cms.useGetCMS(id);
    const { data: containerCategories, status: containerCategoriesStatus } = api.commerce.autocomplete.useFindContainerCategories();
    const validatorResolver = useValidator(new ContainerValidator(), "cleanValuesBeforeValidation");
    const form = useForm<ContainerDocument>({
        resolver: validatorResolver,
        values: data || {},
    });
    const containerName = form.getValues("containerName") ?? data?.containerName ?? "";

    return (
        <Form
            form={form}
            formPath={Paths.Cms.CmsForm}
            listPath={Paths.Cms.CmsList}
            initQueryStatus={status}
            submitMutation={api.commerce.cms.useCreateOrUpdateCMS(id)}
            id={id}
            isLoading={containerCategoriesStatus === "pending"}
            headerText={{ resource: "common.cms", params: { id: !isCopy && id ? id : "" } }}
            customTitle={id && containerName && `${t(common.cms)} - ${containerName}`}
        >
            <FormRow>
                <FormAutocomplete
                    name="categoryName"
                    label={t(common.categoryName)}
                    items={containerCategories}
                    freeSolo
                />

                <FormTextField
                    name="containerName"
                    label={t(common.containerName)}
                />

                <FormDivisionSelect
                    label={t(common.divisions)}
                    multiple
                    disableEmpty
                />

                <Grid
                    item
                    style={{ position: "absolute", right: "20px" }}
                >
                    <Button
                        label={common.downloadProducts}
                        onClick={() => {
                            const { containerName, products = [], sections = [] } = form.getValues() || {};
                            const rootStr = products.length > 0 ? `${containerName};${products.map((p) => p.productId).join(",")}\n` : "";

                            const csvValues = `${rootStr}${sections
                                .filter((x) => x.products?.length > 0 && x.sectionName)
                                .map((x) => [x.sectionName, x.products.map((p) => p.productId).join(",")].join(";"))
                                .join("\n")}`;

                            exportAsCsv(csvValues, containerName);
                        }}
                    />
                </Grid>
            </FormRow>

            <FormRow>
                <FormSelect
                    name="profileTypes"
                    label={t(common.profileTypes)}
                    items={ProfileTypes}
                    disableEmpty
                    multiple
                />

                <FormTextField
                    name="comment"
                    label={t(common.comment)}
                />

                <FormSwitch
                    name="isActive"
                    label={t(common.isActive)}
                />
            </FormRow>

            <FormRow>
                <FormDateTime
                    name="validFrom"
                    label={t(common.start)}
                />

                <FormDateTime
                    name="validTo"
                    label={t(common.end)}
                />
            </FormRow>

            <CartContextPredicate />

            <FormRow>
                <FormProductsDragAndDrop
                    name="products"
                    label={common.products}
                    width="fullWidth"
                />
            </FormRow>

            <ContentData useCollapse={false} />

            <FormList<ContainerSection>
                name="sections"
                label="sections"
                defaultValue={{ isActive: true }}
                type="virtualize"
                canCopyItem
                canExportItem
                exportPartItem={async (item) => {
                    await copyToClipboard((item.products || []).map((p) => (typeof p === "string" ? p : p.productId)).join(","));
                }}
                exportPartLabel={t(common.exportProducts)}
            >
                {() => {
                    return (
                        <>
                            <FormRow>
                                <FormTextField
                                    name="sectionName"
                                    label={t(common.sectionName)}
                                />

                                <FormSwitch
                                    name="isActive"
                                    label={t(common.isActive)}
                                />
                            </FormRow>

                            <FormRow>
                                <ContentData hideLabel />
                            </FormRow>

                            <FormRow>
                                <FormProductsDragAndDrop
                                    name="products"
                                    label={common.products}
                                    width="fullWidth"
                                />
                            </FormRow>
                        </>
                    );
                }}
            </FormList>
        </Form>
    );
};

export default CmsForm;
