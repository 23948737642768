import { SimplifiedProduct } from "Commerce-Offer";
import type { Identifier } from "dnd-core";
import { useDebouncedCallback } from "use-debounce";
import useWidth from "utils/hooks/useWidth";
import { RefAttributes, forwardRef, useEffect, useRef, useState } from "react";
import React from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeGrid } from "react-window";
import { Grid } from "@mui/material";
import { api } from "api";
import _ from "lodash";
import { common } from "translations";
import { ValidationError } from "control-types";
import { Translation } from "translations/Translation";
import { useTranslation } from "utils-ts/hooks";
import { SpinningPreloader } from "components-ts/preloaders";
import ProductChip from "components-ts/visualizations/ProductChip";
import { Button } from "../buttons";
import { useVirtualizedParentResize } from "../virtualizations";
import TextField from "./TextField";

const columnsWidthMap = new Map([
    ["xs", 1],
    ["sm", 0.5],
    ["md", 0.33],
    ["lg", 0.25],
    ["xl", 0.2],
]);

type Product = {
    productId: string;
};

type ProductsDragAndDropProps = {
    label: Translation;
    value: Array<Product>;
    onChange: (newValue: Array<Product>) => void;
    splitValues?: boolean;
    error?: ValidationError;
    readOnly?: boolean;
} & RefAttributes<HTMLDivElement>;

interface ProductProps {
    id: Product;
    product: SimplifiedProduct;
    readOnly?: boolean;
    index: number;
    handleRemove: (product: Product) => void;
    moveProduct: (dragIndex: number, hoverIndex: number) => void;
    style: React.CSSProperties;
}

const Product: React.FC<ProductProps> = ({ id, index, moveProduct, readOnly, product, handleRemove, style }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [{ handlerId }, drop] = useDrop<
        {
            index: number;
            id: Product;
            type: string;
        },
        void,
        { handlerId: Identifier | null }
    >(
        {
            accept: "product",
            collect(monitor) {
                return {
                    handlerId: monitor.getHandlerId(),
                };
            },
            canDrop: () => {
                return true;
            },
            drop: (item) => {
                if (!ref.current) {
                    return;
                }
                const dragIndex = item.index;
                const hoverIndex = index;

                if (dragIndex === hoverIndex) {
                    return;
                }

                moveProduct(dragIndex, hoverIndex);
            },
        },
        [id, index]
    );

    const [{ isDragging }, drag] = useDrag({
        type: "product",
        item: () => {
            return { id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));
    return (
        <div
            ref={ref}
            style={{
                opacity,
                cursor: "move",
                display: "inline-flex",
                margin: "4px",
                ...style,
            }}
            data-handler-id={handlerId}
        >
            <ProductChip
                value={id.productId}
                handleRemove={(value) => handleRemove({ productId: value })}
                product={product}
                readOnly={readOnly}
                withToolTip={!isDragging}
            />
        </div>
    );
};

const ProductsDragAndDrop = forwardRef<HTMLDivElement, ProductsDragAndDropProps>(
    ({ label, value, onChange, error, readOnly, splitValues = true }, ref) => {
        const { data, status, queryParams, setQueryParams } = api.commerce.offers.products.useGetSimplifiedProduct();
        const virtualizedParentResize = useVirtualizedParentResize();
        const [products, setProducts] = useState<Record<number, SimplifiedProduct>>({});
        const [productsIds, setProductsIds] = useState<string[]>([]);
        const { t } = useTranslation();

        useEffect(() => {
            if (value.length > 0) {
                setProductsIds(value.map((v) => (typeof v === "string" ? v : v.productId)));
            }
        }, [value.length > 0]);

        useEffect(() => {
            if (status === "success") {
                const temp = {
                    ...products,
                };

                (data || []).forEach((p) => {
                    temp[Number(p.productId)] = p;
                });

                setProducts(temp);
                setTimeout(() => {
                    setQueryParams({ productIds: (productsIds || []).slice(250, 500).filter((p) => products[Number(p)] === undefined) });
                    setProductsIds((productsIds || []).slice(250).filter((p) => products[Number(p)] === undefined));
                }, 500);
            }
        }, [status, (productsIds || []).length]);

        useEffect(() => {
            if (status === "pending" && (queryParams.productIds || []).length === 0 && (productsIds || []).length > 0) {
                setQueryParams({ productIds: (productsIds || []).slice(0, 250).filter((p) => products[Number(p)] === undefined) });
            }
        }, [productsIds]);

        const [inputValue, setInputValue] = useState("");

        const handleRemove = (v: Product) => {
            let newValues = value.filter((x) => x.productId !== v.productId);
            if (!newValues?.length) {
                newValues = [];
            }

            onChange(newValues);
            if (virtualizedParentResize) {
                virtualizedParentResize();
            }
        };

        const setFieldValue = useDebouncedCallback((eventValue: string) => {
            setInputValue("");
            const newValues = _(String(eventValue))
                .split(splitValues ? /[,;\s]/ : /$/)
                .filter((x) => Boolean(x) && _.isNumber(Number(x)) && Number(x) > 0)
                .map((x) => ({ productId: String(x) }))
                .uniq()
                .filter((x) => (value || []).every((y) => (typeof y === "string" ? y !== x.productId : y.productId !== x.productId)))
                .value();

            if (eventValue && newValues && newValues.length) {
                onChange([...(value || []), ...newValues]);
                setProductsIds(newValues.map((v) => (typeof v === "string" ? v : v.productId)));

                if (virtualizedParentResize) {
                    virtualizedParentResize();
                }
            }
        }, 750);

        const width = useWidth();
        const columnWidth = columnsWidthMap.get(width) ?? 1;
        const columnCount = 1 / columnWidth;
        const rowCount = Math.ceil(value.length / columnCount);
        const rowsHeight = rowCount * 58; //rowHeight is 50, but 2 * margin(4px) so is 58

        const moveProduct = (dragIndex: number, hoverIndex: number) => {
            const tempItems = value.filter((_, index) => index != dragIndex);
            const t = [...tempItems.slice(0, hoverIndex), value[dragIndex], ...tempItems.slice(hoverIndex)];
            onChange(t);
            setProductsIds(t.filter((t) => t !== undefined).map((i) => (typeof i === "string" ? i : i.productId)));
        };

        return (
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={1}
            >
                <Grid
                    container
                    item
                    direction="row"
                    justifyContent="flex-end"
                >
                    <Button
                        label={common.clearProductList}
                        onClick={() => onChange([])}
                        color="error"
                    />
                </Grid>
                <Grid
                    item
                    style={{ flex: "1 1 auto", height: "75vh", width: "95vw", position: "relative", maxHeight: `${rowsHeight}px` }}
                >
                    <DndProvider backend={HTML5Backend}>
                        <AutoSizer>
                            {({ height, width }: { height?: number | undefined; width?: number | undefined }) => {
                                if (height === undefined || width === undefined) {
                                    return <SpinningPreloader />;
                                }

                                return (
                                    <FixedSizeGrid<Product[]>
                                        columnWidth={Math.floor(width * columnWidth * 0.99)}
                                        rowHeight={50}
                                        columnCount={columnCount}
                                        rowCount={rowCount}
                                        height={height}
                                        width={width}
                                        itemData={value}
                                        itemKey={({ rowIndex, columnIndex, data }) => {
                                            const index = rowIndex * columnCount + columnIndex;
                                            const item = data[index];

                                            if (item === undefined) {
                                                return `undefined-${rowIndex}-${columnIndex}`;
                                            } else {
                                                return typeof item === "string" ? item : item.productId;
                                            }
                                        }}
                                        overscanRowCount={2}
                                    >
                                        {({ rowIndex, columnIndex, style, data }) => {
                                            const index = rowIndex * columnCount + columnIndex;
                                            const item = data[index];
                                            if (item === undefined) {
                                                return <div style={style} />;
                                            }

                                            return (
                                                <Product
                                                    id={item}
                                                    index={index}
                                                    moveProduct={moveProduct}
                                                    readOnly={readOnly}
                                                    product={products[Number(typeof item === "string" ? item : item.productId)]}
                                                    handleRemove={handleRemove}
                                                    style={style}
                                                />
                                            );
                                        }}
                                    </FixedSizeGrid>
                                );
                            }}
                        </AutoSizer>
                    </DndProvider>
                </Grid>

                <Grid item>
                    <TextField
                        ref={ref}
                        fullWidth
                        label={t(label)}
                        placeholder={t(label)}
                        onChange={(newValue) => {
                            setInputValue(newValue);
                            setFieldValue(newValue);
                        }}
                        onBlur={(event) => {
                            event.persist();
                            const {
                                target: { value: val },
                            } = event;
                            setFieldValue(val);
                        }}
                        value={inputValue}
                        error={error}
                    />
                </Grid>
            </Grid>
        );
    }
);

export default ProductsDragAndDrop;
