import { plPL as corePlPL } from "@mui/material/locale";
import { createTheme } from "@mui/material/styles";
import { plPL as dataGridDplPL } from "@mui/x-data-grid";
import { plPL } from "@mui/x-date-pickers/locales";

export default createTheme(
    {
        typography: {
            useNextVariants: true,
        },
        palette: {
            type: "light",
            primary: {
                main: "#283593",
                light: "#535da8",
                dark: "#1c2566",
                contrastText: "#fff",
            },
            secondary: {
                main: "#A0004D",
                light: "#b33370",
                dark: "#700035",
                contrastText: "#fff",
            },
            error: {
                light: "#ff6a53",
                main: "#de3428",
                dark: "#a30000",
                contrastText: "#fff",
            },
            warning: {
                light: "#fff263",
                main: "#fbc02d",
                dark: "#c49000",
                contrastText: "#fff",
            },
            success: {
                light: "#8cf250",
                main: "#56bf14",
                dark: "#0e8e00",
                contrastText: "#fff",
            },
            text: {
                primary: "rgba(0, 0, 0, 0.87)",
                secondary: "rgba(0, 0, 0, 0.54)",
                disabled: "rgba(0, 0, 0, 0.38)",
                hint: "rgba(0, 0, 0, 0.38)",
            },
            divider: "rgba(0, 0, 0, 0.12)",
            common: { black: "#000", white: "#fff" },
            action: {
                hoverOpacity: 0.08,
                hover: "rgba(0, 0, 0, 0.08)",
                selected: "rgba(0, 0, 0, 0.14)",
                disabledBackground: "rgba(0, 0, 0, 0.12)",
                disabled: "rgba(0, 0, 0, 0.26)",
                active: "rgba(0, 0, 0, 0.54)",
            },
        },
        components: {
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        "color": "#383838",
                        "&.Mui-required": {
                            fontWeight: 600,
                        },
                    },
                    asterisk: {
                        fontWeight: 600,
                        color: "#de3428",
                    },
                },
            },
            aste: {},
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        color: "#383838",
                    },
                },
            },
            MuiAutocomplete: {
                defaultProps: {
                    renderOption: (props, option, state, ownerState) => (
                        <li
                            {...props}
                            key={option.key ? option.key : option.id ? option.id : option}
                        >
                            {ownerState.getOptionLabel(option)}
                        </li>
                    ),
                },
            },
        },
    },
    corePlPL,
    dataGridDplPL,
    plPL
);
