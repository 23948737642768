import { Pool, determineEffectType } from "components/Controls/EffectForm/model";
import { promotionTypes } from "components/Controls/promotionTypes";
import { toDictionary } from "utils/extensions/arrayExtensions";
import { CartPredicate, User } from "utils/models/CommerceForms";
import { voucherKinds } from "translations";

export class VoucherGenerator {
    constructor(form = {}) {
        this.id = form.id;
        this.name = form.name;
        this.slug = form.slug;
        this.validFrom = form.validFrom;
        this.validTo = form.validTo;
        this.isRepeatable = form.isRepeatable;
        this.isSending = form.isSending;
        this.isReminding = form.isReminding;
        this.isActive = form.isActive;
        this.addTags = form.addTags;
        this.definitions = form.definitions ? form.definitions.map((d) => new Voucher(d)) : form.definitions;
        this.user = form.user && new User(form.user);
        this.contentData = form.contentData ? toDictionary(form.contentData) : form.contentData;
        this.generateOnAddedSegments = form.generateOnAddedSegments;
        this.isSystem = form.isSystem;
        this.generateOnRegistration = form.generateOnRegistration;
        this.generateOnTokenRefresh = form.generateOnTokenRefresh;
        this.divisions = form.divisions;
    }
}

export class VoucherDefinition {
    constructor(definition = {}) {
        this.divisions = definition.divisions;
        this.useLimit = definition.useLimit;
        this.voucherPercent = definition.voucherPercent;
        this.paymentAmount = definition.paymentAmount;
        this.cashbackPercent = definition.cashbackPercent;
        this.cashbackLimit = definition.cashbackLimit;
        this.merchant = definition.paymentAmount ? (definition.paymentAmount.FRS ? "frs" : "f24") : undefined;
        this.voucherType = definition.voucherPercent
            ? voucherKinds.percent_voucher
            : definition.voucherValue
            ? voucherKinds.value_voucher
            : definition.paymentAmount
            ? voucherKinds.amount_voucher
            : definition.cashbackPercent
            ? voucherKinds.cashback_voucher
            : voucherKinds.promotion_voucher;
        this.voucherValue = definition.voucherValue;
        this.includeAlcohol = definition.includeAlcohol;
        this.includeTobacco = definition.includeTobacco;
        this.isCumulative = definition.isCumulative;
        this.userActivationLimit = definition.userActivationLimit;
        this.userActivationLimitExpirationPeriod = definition.userActivationLimitExpirationPeriod;
        this.onlyIdentityVerifiedFirstOrder = definition.onlyIdentityVerifiedFirstOrder;
        this.requirements = definition.requirements && new CartPredicate(definition.requirements);
        this.voucherPromotions = definition.voucherPromotions
            ? definition.voucherPromotions.map((p) => new VoucherPromotion(p))
            : definition.voucherPromotions;
        this.includeProducts = definition.includeProducts;
        this.excludeProducts = definition.excludeProducts;
        this.allowedEmailHosts = definition.allowedEmailHosts;
    }
}

class VoucherPromotion {
    constructor(voucherPromotion = {}) {
        this.promotionId = voucherPromotion.id;
        this.definition = new PromotionDefinition(voucherPromotion.definition);
    }
}

class PromotionDefinition {
    constructor(definition = { pools: [] }) {
        let progressiveQuantities = [];
        this.promotionType = definition.promotionType;
        if (this.promotionType === promotionTypes.Progressive) {
            const [firstPool = {}] = definition.pools || [];
            progressiveQuantities = firstPool.progressiveQuantities;
        }
        this.isCumulative = definition.isCumulative;
        this.includeAlcohol = definition.includeAlcohol;
        this.includeTobacco = definition.includeTobacco;
        this.requiresConsent = definition.requiresConsent;
        this.cartActivationLimit = definition.cartActivationLimit;
        this.userActivationLimit = definition.userActivationLimit;
        this.promotionActivationLimit = definition.promotionActivationLimit;
        this.userActivationLimitExpirationPeriod = definition.userActivationLimitExpirationPeriod;
        this.userActivationLimitExpirationInterval = definition.userActivationLimitExpirationInterval;
        this.effects = definition.effects
            ? definition.effects.map((e) => determineEffectType(e, definition.promotionType, progressiveQuantities))
            : definition.effects;
        this.pools = definition.pools ? definition.pools.map((p) => new Pool(p)) : definition.pools;
    }
}

export class Voucher {
    constructor(voucher = {}) {
        this.id = voucher.id;
        this.complaintId = voucher.complaintId;
        this.batchStatus = voucher.batchStatus;
        this.codeLength = voucher.codeLength;
        this.voucherCount = voucher.voucherCount;
        this.userId = voucher.userId;
        this.validFrom = voucher.validFrom;
        this.validTo = voucher.validTo;
        this.name = voucher.name;
        this.slug = voucher.slug;
        this.definition = new VoucherDefinition(voucher.definition);
        this.isVerificationRequired = voucher.isVerificationRequired;
        this.addTags = voucher.addTags;
        this.isReminding = voucher.isReminding;
        this.campaignName = voucher.campaignName;
        this.validForDays = voucher.validForDays;
        this.contentData = voucher.contentData ? toDictionary(voucher.contentData) : voucher.contentData;
        this.useCount = voucher.useCount || 0;
        this.isSystem = voucher.isSystem;
        this.isDisallowed = voucher.isDisallowed;
        this.isUserDisallowed = voucher.isUserDisallowed;
        this.isGlobal = voucher.isGlobal;
    }
}
