import { OrderDocument, OrderSearchParameters, UserContext } from "Commerce-Core";
import ordersStatuses from "resource/ordersStatuses.json";
import packagingMethods from "resource/packagingMethods.json";
import paymentMethods from "resource/paymentMethods.json";
import segments from "resource/segments.json";
import { ean as eanRegex, productId as productIdRegex, voucherId as voucherIdRegex } from "utils/validators/regexes";
import { ShippingAddress } from "Shared";
import { api } from "api";
import { segments as tsegments } from "translations";
import { DeliveryMethods, ObsoleteDeliveryMethods, Warehouses } from "types/sharedEnums";
import { useColumns, useFacets, useTranslation } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { QueryTable } from "components-ts/tables";
import { View } from "components-ts/view";

const OrderSearch: React.FC = () => {
    const { t } = useTranslation("segments");
    const columns = useColumns<OrderDocument>(() => [
        {
            property: "userId",
            label: { resource: `orders:customer` },
            actionType: "link",
            asText: (item) => {
                return item.userId;
            },
            link: (item) => {
                return Paths.GeneratePath(Paths.Customer.Form, { customerId: item.userId });
            },
        },
        {
            property: "userContext",
            label: "accountType",
            as: (_value: unknown, item: OrderDocument) => {
                const { userContext } = item;
                const { segments: seg = [] } = userContext;
                let type: string[] = [];
                if (seg.includes(`commerce:${tsegments.BusinessAccount}`)) {
                    type = [...type, t(tsegments.BusinessAccount)];
                }
                if (seg.includes(`commerce:${tsegments.OfficeAccount}`)) {
                    type = [...type, t(tsegments.OfficeAccount)];
                }
                if (seg.includes(`commerce:${tsegments.HomeAccount}`)) {
                    type = [...type, t(tsegments.HomeAccount)];
                }

                return type.map((x, i) => <div key={i}>{x}</div>);
            },
        },
        {
            property: "id",
            label: { resource: `orders:orderId` },
            actionType: "link",
            asText: (item) => {
                return item.id;
            },
            link: (item) => {
                return Paths.GeneratePath(Paths.Customer.OrderForm, { customerId: item.userId, orderNumber: item.id.split("/")[1] });
            },
        },
        {
            property: "fullName",
            label: "customer",
            as: (_value: unknown, item: OrderDocument) => {
                const name = item.userContext.segments.includes(tsegments.OfficeAccount)
                    ? item.billingAddress.companyName
                    : `${item.fullName.firstName} ${item.fullName.lastName}`;
                if (!name) {
                    return "";
                } else {
                    return name;
                }
            },
        },
        {
            property: "documents",
            label: { resource: `orders:invoice` },
            as: (_value: unknown, item: OrderDocument) => {
                return item.documents
                    .map((d) => d.documentReference)
                    .filter((d) => Boolean(d) && (d.documentType === "Invoice" || d.documentType === "ReleaseNotice"))
                    .map((d) => d.documentNumber)
                    .join("\n");
            },
        },
        {
            property: "placedAt",
            label: { resource: `orders:placedAt` },
            as: "date",
        },
        {
            property: "reservation",
            label: "deliveryAt",
            as: "OrderReservation",
        },
        {
            property: "status",
            label: "status",
            as: (value: unknown, _item: OrderDocument) => {
                const orderStatus = ordersStatuses.find((o) => o.value === value);

                return orderStatus?.name;
            },
        },
        {
            property: "totals.total",
            label: { resource: `orders:grossTotal` },
            as: "BalanceAmount",
        },
        {
            property: "shippingAddress",
            label: { resource: `orders:delivery` },
            as: (value: unknown) => {
                const address = value as ShippingAddress;

                return (
                    <>
                        <strong>{`${address.postcode} ${address.city} `}</strong>
                        <br />
                        {`${address.street} ${address.buildingNumber} ${address.apartmentNumber ? `m. ${address.apartmentNumber}` : ""}`}
                    </>
                );
            },
        },
        {
            property: "isEarlyEntranceAllowed",
            label: { resource: `orders:isEarlyEntranceAllowedShort` },
            as: (value: unknown) => {
                const val = value as boolean;

                return val ? "Tak" : "Nie";
            },
        },
    ]);

    const { data, status } = api.commerce.autocomplete.useFindCampaings();

    const facets = useFacets<OrderSearchParameters>(() => [
        {
            property: "search",
            label: { resource: "orders:search" },
        },
        {
            property: "deliveryFrom",
            label: { resource: "orders:deliveryFrom" },
            as: "date",
        },
        {
            property: "deliveryTo",
            label: { resource: "orders:deliveryTo" },
            as: "date",
        },
        {
            property: "warehouse",
            label: { resource: "orders:warehouse" },
            as: "checkboxList",
            items: Warehouses,
        },
        {
            property: "status",
            label: { resource: "orders:status" },
            as: "checkboxList",
            items: ordersStatuses,
        },
        {
            property: "deliveryMethod",
            label: { resource: "orders:deliveryMethod" },
            as: "checkboxList",
            items: DeliveryMethods.filter((o) => !ObsoleteDeliveryMethods.includes(o)),
        },
        {
            property: "paymentMethod",
            label: { resource: "orders:paymentMethod" },
            as: "checkboxList",
            items: paymentMethods,
        },
        {
            property: "packagingMethod",
            label: { resource: "orders:packagingMethod" },
            as: "checkboxList",
            items: packagingMethods,
        },
        {
            property: "segment",
            label: { resource: "orders:segments" },
            as: "checkboxList",
            useAndOperator: true,
            items: segments.filter((s) => s.showInFacetSearch),
        },
        {
            property: "campaignName",
            label: { resource: "orders:campaignName" },
            as: "autocomplete",
            items: status === "success" ? data : [],
        },
        {
            property: "productId",
            label: { resource: "orders:products" },
            as: "listInput",
            listInputValueCheck: (v) => productIdRegex.test(v),
        },
        {
            property: "ean",
            label: { resource: "orders:eans" },
            as: "listInput",
            listInputValueCheck: (v) => eanRegex.test(v),
        },
        {
            property: "voucherId",
            label: { resource: "orders:vouchers" },
            as: "listInput",
            listInputValueCheck: (v) => voucherIdRegex.test(v),
        },
    ]);

    return (
        <View
            headerText={{ resource: "orders:orderSearch" }}
            isMainView
        >
            <QueryTable
                queryHook={api.commerce.orders.useFindSearchOrders}
                columns={columns}
                hideToggle
                canAddItem={false}
                facets={facets}
            />
        </View>
    );
};

export default OrderSearch;
