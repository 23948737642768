import Button from "components/Button/Button";
import { ArrayValidationError } from "components/Controls";
import Layout from "components/Grid/Layout";
import { actions } from "store/cache/products/actions";
import { useCommonTranslation } from "utils/hooks";
import { exportAsCsv } from "utils/utilsFunctions";
import { productId } from "utils/validators/regexes";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { FixedSizeList } from "react-window";
import { Grid, TextField } from "@mui/material";
import { DiscountProduct } from "../../model";
import Product from "./Product";

const Products = ({ initialProducts, fields, meta, products }) => {
    const [searchedIndex, setSearchedIndex] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const listRef = useRef(null);
    const { t, common } = useCommonTranslation();
    const dispatch = useDispatch();
    useEffect(() => {
        if (initialProducts && initialProducts.length > 0) {
            dispatch(actions.getProducts(initialProducts.map((p) => p.productId).filter(Boolean)));
        }
    }, [dispatch, initialProducts]);
    return (
        <Layout
            isEnlarged
            headerText={t(common.products)}
        >
            <Grid
                container
                justifyContent="space-between"
                direction="row"
            >
                <TextField
                    variant="standard"
                    style={{ marginBottom: 20 }}
                    value={searchValue}
                    placeholder="Szukaj po ID"
                    onChange={(event) => {
                        event.persist();
                        const value = event?.target?.value;
                        setSearchValue(value);
                        if (productId.test(event.target.value)) {
                            const index = (fields.getAll() || [])
                                .map((p, i) => ({
                                    productId: p.productId,
                                    index: i,
                                }))
                                .find((x) => x.productId === value)?.index;
                            if (index !== undefined) {
                                listRef.current.scrollToItem(index);

                                setSearchedIndex(index);
                            }
                        }
                    }}
                    onBlur={() => {
                        setSearchValue("");
                        setSearchedIndex("");
                    }}
                />
                <Button
                    color="primary"
                    onClick={() => {
                        const csvValues = `${t(common.productId)};${t(common.modifier)};${t(common.value)}\n${(products || [])
                            .map((x) => [x.productId, t(x.type), x.modifier[x.type]?.toString().replace(".", ",")].join(";"))
                            .join("\n")}`;
                        exportAsCsv(csvValues, "Export");
                    }}
                    variant="contained"
                >
                    {t(common.exportProducts)}
                </Button>
            </Grid>
            <ArrayValidationError {...meta} />
            <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                direction="column"
            >
                <FixedSizeList
                    ref={listRef}
                    height={500}
                    itemCount={fields.length}
                    itemSize={100}
                    width={"100%"}
                    itemData={{
                        remove: fields.remove,
                        field: fields.name,
                        searchedIndex,
                    }}
                >
                    {Product}
                </FixedSizeList>
                <Grid
                    item
                    style={{ marginLeft: "auto" }}
                >
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            const newItem = new DiscountProduct({
                                type: "discountPercent",
                                modifier: {
                                    discountPercent: 1,
                                },
                            });
                            fields.push(newItem);
                            listRef.current.scrollToItem(fields.length + 1);
                        }}
                    >
                        {t(common.add)}
                    </Button>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default Products;
