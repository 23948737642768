import { manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, createAction } from "utils/extensions";

const types = {
    updateInitProductsFilter: createAction("UPDATE_INIT_PRODUCTS_FILTER", manager),
    initProductsSelectSupplier: createAction("INIT_PRODUCTS_SELECT_SUPPLIER", manager),
    initProductsGetPage: new AsyncTypes("INIT_PRODUCTS_GET_PAGE", operations),
    getUserSuppliers: new AsyncTypes("GET_USER_SUPPLIERS", operations),
    getProductInitEans: new AsyncTypes("GET_PRODUCT_INIT_EANS", operations),
    getProductInitProductIds: new AsyncTypes("GET_PRODUCT_INIT_PRODUCT_IDS", operations),
    downloadInitProductsFile: new AsyncTypes("DOWNLOAD_INIT_PRODUCTS_FILE", operations),
    downloadInitProductsBrandsFile: new AsyncTypes("DOWNLOAD_INIT_PRODUCTS_BRANDS_FILE", operations),
    downloadInitProductsMeasureFile: new AsyncTypes("DOWNLOAD_INIT_PRODUCTS_MEASURE_FILE", operations),
    deleteProductInitTypeOperations: new AsyncTypes("INIT_PRODUCTS_DELETE", operations, [asAsyncFormTypes]),
};

export default types;
