import { UserPredicate as UserPredicateType } from "Commerce-Pricing";
import { DateLeadPredicate as DateLeadPredicateType, DatePredicate as DatePredicateType, DeliveryPredicate } from "Commerce-Shared";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Collapse, Grid } from "@mui/material";
import { common } from "translations";
import { DeliveryMethods, PackagingMethods, PaymentMethods } from "types/sharedEnums";
import { useTranslation } from "utils-ts/hooks";
import { Switch } from "components-ts/controls";
import { FormPrefixProvider, usePrefixContext } from "components-ts/forms/contexts";
import { FormColumn, FormRow } from "components-ts/forms/layout";
import FormDecimal from "../FormDecimal";
import FormInteger from "../FormInteger";
import FormList from "../FormList";
import FormSelect from "../FormSelect";
import FormToggleButton from "../FormToggleButton";
import DateLeadPredicate from "./DateLeadPredicate";
import DatePredicate from "./DatePredicate";
import PostcodePredicate from "./PostcodePredicate";
import SegmentPredicate from "./SegmentPredicate";

const UserPredicate: React.FC<{ readOnly?: boolean }> = ({ readOnly }) => {
    const { t } = useTranslation();
    const prefix = usePrefixContext();
    const name = prefix ? `${prefix}.user` : "user";
    const { getValues, setValue } = useFormContext();
    const userPredicate = getValues(name) as UserPredicateType;
    const [haveSegments, setHaveSegments] = useState<boolean>(false);
    const [haveOrderRequirements, setHaveOrderRequirements] = useState<boolean>(false);
    const [havePersonalTarget, setHavePersonalTarget] = useState<boolean>(false);

    useEffect(() => {
        const { segments = [], orders = {}, personalTarget = {} } = userPredicate || {};
        if (!haveSegments && segments.some((s) => (s.excluded || []).length > 0 || (s.included || []).length > 0)) {
            setHaveSegments(true);
        }

        if (!haveOrderRequirements && Object.values(orders).some((k) => k !== undefined)) {
            setHaveOrderRequirements(true);
        }

        if (!havePersonalTarget && Object.values(personalTarget).some((k) => k !== undefined)) {
            setHavePersonalTarget(true);
        }
    }, [userPredicate]);

    return (
        <FormColumn>
            <FormPrefixProvider name="user">
                <Grid item>
                    <Switch
                        label={t(common.orderRequirements)}
                        value={haveOrderRequirements}
                        onChange={(newValue) => {
                            setHaveOrderRequirements(newValue);
                            if (!newValue) {
                                setValue(`${name}.orders`, undefined);
                            }
                        }}
                        readOnly={readOnly}
                    />
                </Grid>

                <Grid
                    item
                    style={{ display: haveOrderRequirements ? undefined : "none" }}
                >
                    <Collapse in={haveOrderRequirements}>
                        <Grid
                            container
                            spacing={2}
                        >
                            <FormPrefixProvider name="orders">
                                <FormRow>
                                    <FormInteger
                                        name="placedOrderCountFrom"
                                        label={t(common.placedOrderCountFrom)}
                                        readOnly={readOnly}
                                    />

                                    <FormInteger
                                        name="placedOrderCountTo"
                                        label={t(common.placedOrderCountTo)}
                                        readOnly={readOnly}
                                    />
                                </FormRow>

                                <FormRow>
                                    <FormInteger
                                        name="placedOrderCountModulo"
                                        label={t(common.placedOrderCountModulo)}
                                        readOnly={readOnly}
                                    />

                                    <FormInteger
                                        name="placedOrderCountModuloRemainder"
                                        label={t(common.placedOrderCountModuloRemainder)}
                                        readOnly={readOnly}
                                    />
                                </FormRow>

                                <FormRow>
                                    <FormInteger
                                        name="invoicedOrderCountFrom"
                                        label={t(common.invoicedOrderCountFrom)}
                                        readOnly={readOnly}
                                    />

                                    <FormInteger
                                        name="invoicedOrderCountTo"
                                        label={t(common.invoicedOrderCountTo)}
                                        readOnly={readOnly}
                                    />
                                </FormRow>

                                <FormRow>
                                    <FormInteger
                                        name="invoicedOrderCountModulo"
                                        label={t(common.invoicedOrderCountModulo)}
                                        readOnly={readOnly}
                                    />

                                    <FormInteger
                                        name="invoicedOrderCountModuloRemainder"
                                        label={t(common.invoicedOrderCountModuloRemainder)}
                                        readOnly={readOnly}
                                    />
                                </FormRow>

                                <FormRow>
                                    <FormInteger
                                        name="membershipOrderCountFrom"
                                        label={t(common.membershipOrderCountFrom)}
                                        readOnly={readOnly}
                                    />

                                    <FormInteger
                                        name="membershipOrderCountTo"
                                        label={t(common.membershipOrderCountTo)}
                                        readOnly={readOnly}
                                    />
                                </FormRow>

                                <FormRow>
                                    <FormInteger
                                        name="membershipOrderCountModulo"
                                        label={t(common.membershipOrderCountModulo)}
                                        readOnly={readOnly}
                                    />

                                    <FormInteger
                                        name="membershipOrderCountModuloRemainder"
                                        label={t(common.membershipOrderCountModuloRemainder)}
                                        readOnly={readOnly}
                                    />
                                </FormRow>

                                <FormRow>
                                    <FormInteger
                                        name="lastDeliveryDaysAgoFrom"
                                        label={t(common.lastDeliveryDaysAgoFrom)}
                                        readOnly={readOnly}
                                    />

                                    <FormInteger
                                        name="lastDeliveryDaysAgoTo"
                                        label={t(common.lastDeliveryDaysAgoTo)}
                                        readOnly={readOnly}
                                    />
                                </FormRow>
                            </FormPrefixProvider>
                        </Grid>
                    </Collapse>
                </Grid>

                <Grid item>
                    <Switch
                        label={t(common.personalTarget)}
                        value={havePersonalTarget}
                        onChange={(newValue) => {
                            setHavePersonalTarget(newValue);
                            if (!newValue) {
                                setValue(`${name}.personalTarget`, undefined);
                            }
                        }}
                        readOnly={readOnly}
                    />
                </Grid>

                <Grid
                    item
                    style={{ display: havePersonalTarget ? undefined : "none" }}
                >
                    <Collapse in={havePersonalTarget}>
                        <Grid
                            container
                            spacing={2}
                        >
                            <FormRow>
                                <FormPrefixProvider name="personalTarget">
                                    <FormDecimal
                                        label={t(common.targetAmountFrom)}
                                        name="targetAmountFrom"
                                        readOnly={readOnly}
                                    />
                                    <FormDecimal
                                        label={t(common.targetAmountTo)}
                                        name="targetAmountTo"
                                        readOnly={readOnly}
                                    />
                                </FormPrefixProvider>
                            </FormRow>
                        </Grid>
                    </Collapse>
                </Grid>

                <Grid item>
                    <Switch
                        label={t(common.haveSegmentsConstraint)}
                        value={haveSegments}
                        onChange={(newValue) => {
                            setHaveSegments(newValue);
                            if (!newValue) {
                                setValue(`${name}.segments`, undefined);
                            }
                        }}
                        readOnly={readOnly}
                    />
                </Grid>

                <Grid
                    item
                    style={{ display: haveSegments ? undefined : "none" }}
                >
                    <Collapse in={haveSegments}>
                        <Grid
                            container
                            spacing={2}
                        >
                            <SegmentPredicate
                                name="segments.0"
                                readOnly={readOnly}
                            />
                        </Grid>
                    </Collapse>
                </Grid>
            </FormPrefixProvider>
        </FormColumn>
    );
};

const CartContextPredicate: React.FC<{ name?: string; readOnly?: boolean }> = ({ name = "requirements", readOnly }) => {
    const { t } = useTranslation();

    return (
        <FormPrefixProvider name={name}>
            <FormRow>
                <UserPredicate />
            </FormRow>

            <FormRow>
                <FormSelect
                    label={t(common.availablePaymentMethods)}
                    name="payment"
                    items={PaymentMethods.map((p) => ({ name: t(p), value: p }))}
                    disableEmpty
                    multiple
                    readOnly={readOnly}
                    subpropertyName="paymentMethod"
                />

                <FormSelect
                    label={t(common.packagingMethods)}
                    name="packaging"
                    items={PackagingMethods.map((p) => ({ name: t(p), value: p }))}
                    disableEmpty
                    multiple
                    readOnly={readOnly}
                    subpropertyName="packagingMethod"
                />
            </FormRow>

            <FormRow>
                <FormPrefixProvider name="total">
                    <FormDecimal
                        label={t(common.orderMinimumValue)}
                        name="minimumValue"
                        readOnly={readOnly}
                    />

                    <FormDecimal
                        label={t(common.orderMaximumValue)}
                        name="maximumValue"
                        readOnly={readOnly}
                    />
                </FormPrefixProvider>
            </FormRow>

            <FormRow>
                <FormList<DeliveryPredicate>
                    name="delivery"
                    defaultValue={{}}
                    label={common.deliveryConditions}
                >
                    {() => {
                        return (
                            <>
                                <FormRow>
                                    <FormSelect
                                        label={t(common.deliveryMethods)}
                                        name="deliveryMethods"
                                        items={DeliveryMethods}
                                        readOnly={readOnly}
                                        multiple
                                        disableEmpty
                                    />
                                </FormRow>

                                <FormRow>
                                    <FormToggleButton<boolean>
                                        label={t(common.isSameDayDelivery)}
                                        name="isSameDayDelivery"
                                        items={[
                                            { value: true, name: "Tak" },
                                            { value: false, name: "Nie" },
                                            { value: undefined, name: "Nie dotyczy" },
                                        ]}
                                        readOnly={readOnly}
                                    />

                                    <FormToggleButton<boolean>
                                        label={t(common.isNonTradeSunday)}
                                        name="isNonTradeSunday"
                                        items={[
                                            { value: true, name: "Tak" },
                                            { value: false, name: "Nie" },
                                            { value: undefined, name: "Nie dotyczy" },
                                        ]}
                                        readOnly={readOnly}
                                    />

                                    <FormToggleButton<boolean>
                                        label={t(common.isMondayAfterNonTradeSunday)}
                                        name="isMondayAfterNonTradeSunday"
                                        items={[
                                            { value: true, name: "Tak" },
                                            { value: false, name: "Nie" },
                                            { value: undefined, name: "Nie dotyczy" },
                                        ]}
                                        readOnly={readOnly}
                                    />

                                    <FormToggleButton<boolean>
                                        label={t(common.isMorningAfterHoliday)}
                                        name="isMorningAfterHoliday"
                                        items={[
                                            { value: true, name: "Tak" },
                                            { value: false, name: "Nie" },
                                            { value: undefined, name: "Nie dotyczy" },
                                        ]}
                                        readOnly={readOnly}
                                    />
                                </FormRow>

                                <FormRow>
                                    <Grid
                                        item
                                        xs={6}
                                    >
                                        <FormList<DatePredicateType>
                                            name="currentDates"
                                            defaultValue={{ isNew: true }}
                                            label={common.currentDates}
                                            viewType="GridContainer"
                                            canAddItem={!readOnly}
                                            canRemoveItem={!readOnly}
                                        >
                                            {() => <DatePredicate readOnly={readOnly} />}
                                        </FormList>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={6}
                                    >
                                        <FormList<DatePredicateType>
                                            name="deliveryDates"
                                            defaultValue={{ isNew: true }}
                                            label={common.deliveryDates}
                                            viewType="GridContainer"
                                            canAddItem={!readOnly}
                                            canRemoveItem={!readOnly}
                                        >
                                            {() => <DatePredicate readOnly={readOnly} />}
                                        </FormList>
                                    </Grid>
                                </FormRow>

                                <FormRow>
                                    <Grid
                                        item
                                        xs={6}
                                    >
                                        <FormList<DateLeadPredicateType>
                                            name="closingDates"
                                            defaultValue={{ date: { isNew: true } }}
                                            label={common.closingDates}
                                            viewType="GridContainer"
                                            canAddItem={!readOnly}
                                            canRemoveItem={!readOnly}
                                        >
                                            {() => <DateLeadPredicate readOnly={readOnly} />}
                                        </FormList>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={6}
                                    >
                                        <FormList
                                            name="postcodes"
                                            defaultValue={{}}
                                            label={common.postcodes}
                                            viewType="GridContainer"
                                            canAddItem={!readOnly}
                                            canRemoveItem={!readOnly}
                                        >
                                            {() => <PostcodePredicate readOnly={readOnly} />}
                                        </FormList>
                                    </Grid>
                                </FormRow>
                            </>
                        );
                    }}
                </FormList>
            </FormRow>
        </FormPrefixProvider>
    );
};

export default CartContextPredicate;
