import { CampaingsAutocomplete, ContentData, DivisionSelect } from "components/Controls";
import { promotionTypes } from "components/Controls/promotionTypes";
import { CommerceForm, FormField } from "components/Form";
import Layout from "components/Grid/Layout";
import roles, { B2BDepartmentRoles, SupportDepartmentRoles } from "consts/roles";
import { useUser } from "context/UserContext/UserContext";
import { reduxForm } from "redux-form";
import warehouses from "resource/warehouses.json";
import { usePromotionFormAutocompleteEffect } from "store/autocomplete";
import { createOrUpdatePromotion, initPromotionForm } from "store/offers/promotions/form";
import { promotionsFormSelector } from "store/offers/selectors";
import { toObject } from "utils/extensions/arrayExtensions";
import { mapRequirements } from "utils/formExtensions";
import { useCommonTranslation, useCopyRouterState, useFormValueSelector } from "utils/hooks";
import { Fragment, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import _ from "lodash";
import { usePush } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { offersEditRoles } from "routing-ts/Routes/OffersRoles";
import { validators } from "../validators";
import { PromotionActivation, PromotionValidation } from "./components";
import PromotionDefinition from "./components/PromotionDefinition";
import { validate } from "./validate";

const formName = "promotions-form";

const mapProductPredicate = (predicate) => {
    const result = {
        products: predicate.products?.length > 0 ? predicate.products : null,
        categories: predicate.categories?.length > 0 ? predicate.categories : null,
        brands: predicate.brands?.length > 0 ? predicate.brands : null,
        producers: predicate.producers?.length > 0 ? predicate.producers : null,
        suppliers: predicate.suppliers?.length > 0 ? predicate.suppliers : null,
        tags: predicate.tags?.length > 0 ? predicate.tags : null,
        merchants: predicate.merchants?.length > 0 ? predicate.merchants : null,
    };

    return !!result.products ||
        !!result.categories ||
        !!result.brands ||
        !!result.producers ||
        !!result.suppliers ||
        !!result.tags ||
        !!result.merchants
        ? result
        : null;
};

const PromotionsForm = ({ handleSubmit, isSubbmitRequested, activationInfo, promotionValidation, isSystem, name, initialValues }) => {
    usePromotionFormAutocompleteEffect();
    const { id, isCopy } = useCopyRouterState();
    const dispatch = useDispatch();
    const { replace } = usePush();
    const { isInAnyRole, isInAnyRoleOrAdmin } = useUser();
    const { t, common } = useCommonTranslation();
    const { warehouses: selectedWarehouses, divisions } = useFormValueSelector(formName, ["warehouses", "divisions"], []);

    useEffect(() => {
        dispatch(initPromotionForm(id, isCopy));
    }, [dispatch, id, isCopy]);

    const submit = async (values) => {
        const { contentData, definition = {}, ...rest } = values;
        const { promotionType } = definition;
        let pools = definition.pools;
        if (promotionType === promotionTypes.Progressive || (!promotionType && definition.effects?.some((e) => e.effectType === "progressive"))) {
            const { effects } = definition;
            const [effect] = effects;
            const { progressive } = effect;
            const { progressiveModifiers } = progressive;
            const progressiveQuantities = progressiveModifiers.map((x) => x.progressiveQuantity);
            pools = pools.map((p) => ({ ...p, progressiveQuantities }));
        }

        const mapped = {
            ...rest,
            definition: {
                ...Object.keys(definition).reduce((values, key) => {
                    if (definition[key] === "") {
                        return values;
                    }

                    return { ...values, [key]: definition[key] };
                }, {}),
                pools: pools?.map((p) => {
                    return {
                        ...p,
                        requiredQuantity: p.requiredQuantity ? p.requiredQuantity : null,
                        requiredValue: p.requiredValue ? p.requiredValue : null,
                        includeProducts: p.includeProducts ? mapProductPredicate(p.includeProducts) : null,
                        excludeProducts: p.excludeProducts ? mapProductPredicate(p.excludeProducts) : null,
                    };
                }),
                requirements: mapRequirements(definition.requirements, (pools?.length ?? 0) === 0),
                effects: definition.effects.map((effect) => {
                    const effectType = effect.effectType;
                    const emptyEffect = { effectType };
                    if (effectType === "progressive") {
                        emptyEffect.cartModifier = effect.progressive;
                    } else {
                        emptyEffect[effectType] = effect[effectType];
                    }

                    if (effect.choiceKey) {
                        emptyEffect.choiceKey = effect.choiceKey;
                    }

                    return emptyEffect;
                }),
            },
            contentData: contentData ? toObject(contentData.filter((c) => Boolean(c.value))) : undefined,
        };

        const { payload } = await dispatch(createOrUpdatePromotion(mapped, !isCopy && id));

        if ((!isCopy && !id) || isCopy) {
            replace(Paths.Offers.PromotionForm, { id: payload.id });
        }
    };

    const readOnly =
        (isSystem && !isCopy) ||
        (isInAnyRole([roles.AXUser, ...B2BDepartmentRoles, ...SupportDepartmentRoles]) && !isInAnyRoleOrAdmin(offersEditRoles));

    return (
        <CommerceForm
            onSubmit={handleSubmit((values) => submit(values))}
            isReadonly={readOnly}
            isSubbmitRequested={isSubbmitRequested}
        >
            <Layout
                main
                headerText={t(common.promotions, {
                    id: !isCopy && id ? id : "",
                })}
                navigationProps={{
                    backProps: { path: Paths.Offers.PromotionsList },
                }}
                customTitle={id && name && `${t(common.promotions)} - ${name}`}
            >
                {!_.isEmpty(promotionValidation) ? <PromotionValidation promotionValidation={promotionValidation} /> : <Fragment />}

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    {!_.isEmpty(activationInfo) ? <PromotionActivation activationInfo={activationInfo} /> : <Fragment />}
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <FormField
                            name="name"
                            type="text"
                            label={t(common.name)}
                            validate={validators.name}
                            readOnly={readOnly}
                        />

                        <CampaingsAutocomplete validate={validators.campaign} />

                        <DivisionSelect selectedDivisions={divisions} />

                        <FormField
                            name="warehouses"
                            type="select"
                            label={t(common.warehouses)}
                            items={warehouses
                                .filter((w) => w.value !== "OMU")
                                .map((o) => {
                                    return { ...o, disabled: o.disabled && !(selectedWarehouses || []).includes(o.value) };
                                })}
                            multiple
                            validate={validators.warehouses}
                        />
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <FormField
                            name="validFrom"
                            type="dateTime"
                            label={t(common.validFrom)}
                            validate={validators.validFrom}
                            readOnly={readOnly}
                        />
                        <FormField
                            name="validTo"
                            type="dateTime"
                            label={t(common.validTo)}
                            validate={validators.validTo}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <FormField
                            name="isActive"
                            type="boolean"
                            label={t(common.isActive)}
                            readOnly={readOnly}
                        />
                        <FormField
                            name="isPositioned"
                            type="boolean"
                            label={t(common.isPositioned)}
                            readOnly={readOnly}
                        />
                        <FormField
                            name="isPromoted"
                            type="boolean"
                            label={t(common.isPromoted)}
                            readOnly={readOnly}
                        />
                    </Grid>

                    <PromotionDefinition readOnly={readOnly} />
                </Grid>
            </Layout>

            <ContentData readOnly={readOnly} />
        </CommerceForm>
    );
};

const stateToProps = (state) => {
    const { isSubbmitRequested, activationInfo, promotionValidation, isSystem, ...form } = promotionsFormSelector(state);

    return {
        isSystem,
        isSubbmitRequested,
        activationInfo,
        promotionValidation,
        name: form.name,
        initialValues: {
            ...form,
        },
    };
};

export default connect(stateToProps)(
    reduxForm({
        form: formName,
        enableReinitialize: true,
        validate,
    })(PromotionsForm)
);
