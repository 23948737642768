import defaultListState from "store/defaultListState";
import { PRODUCTS_GET_PAGE, PRODUCTS_GET_PAGE_FAILURE, PRODUCTS_GET_PAGE_SUCCESS, UPDATE_PRODUCTS_FILTER } from "./actions";

const initialState = {
    ...defaultListState,
    filterValues: {},
};

export const productsList = (state = initialState, action) => {
    state = state || initialState;
    switch (action.type) {
        case UPDATE_PRODUCTS_FILTER:
            return { ...state, filterValues: action.payload };
        case PRODUCTS_GET_PAGE:
            return { ...state, isLoading: true };
        case PRODUCTS_GET_PAGE_SUCCESS:
            return {
                ...state,
                items: action.payload.items.map((x) => {
                    const { productId, brand, attributesFrom, subbrand, name, grammage, eans, unitOfMeasure, producer, supplier } = x;
                    const bbState =
                        x.attributesFromIntegrations && "Brandbank" in x.attributesFromIntegrations
                            ? x.attributesFromIntegrations["Brandbank"].state
                            : false;
                    const bioplanetState =
                        x.attributesFromIntegrations && "Bioplanet" in x.attributesFromIntegrations
                            ? x.attributesFromIntegrations["Bioplanet"].state
                            : false;

                    let grammageText = "";
                    switch (unitOfMeasure) {
                        case "Kilogram":
                            grammageText = grammage < 1 ? `${grammage * 1000}g` : `${grammage}kg`;
                            break;
                        case "Litre":
                            grammageText = grammage < 1 ? `${grammage * 1000}ml` : `${grammage}l`;
                            break;
                        case "Piece":
                        default:
                            grammageText = `${grammage}szt`;
                            break;
                    }

                    return {
                        productId,
                        brand,
                        subbrand,
                        name,
                        grammage,
                        attributesFrom,
                        eans: eans.join(","),
                        unitOfMeasure,
                        nameMerged: `${name} ${grammageText}`,
                        producer,
                        supplier,
                        bbState,
                        bioplanetState,
                    };
                }),
                pageIndex: action.payload.pageIndex,
                pageSize: action.payload.pageSize,
                totalCount: action.payload.totalCount,
                isLoading: false,
            };
        case PRODUCTS_GET_PAGE_FAILURE:
            return { ...state };
        default:
            return state;
    }
};
