import { RSAA } from "redux-api-middleware";
import { asAsyncFormTypes } from "utils/extensions/arrayExtensions";
import { types } from "./types";

export const initDiscountForm = (id, isCopy) => async (dispatch) => {
    await dispatch({ type: types.initDiscountForm });

    if (id) {
        dispatch({
            [RSAA]: {
                endpoint: `api/v1/offer/products/discounts/${id}`,
                headers: { "Content-Type": "application/json" },
                method: "GET",
                types: [types.getDiscount.request, { type: types.getDiscount.success, meta: { isCopy } }, types.getDiscount.failure],
            },
        });
    }

    if (id && !isCopy) {
        dispatch({
            [RSAA]: {
                endpoint: `api/v1/offer/products/discounts/${id}/validation`,
                headers: { "Content-Type": "application/json" },
                method: "GET",
                types: types.getDiscountValidation.all,
            },
        });
    }
};

export const createOrUpdateDiscount = (values, id) => async (dispatch) => {
    return await dispatch({
        [RSAA]: {
            endpoint: id ? `api/v1/offer/products/discounts/${id}` : `api/v1/offer/products/discounts/`,
            headers: { "Content-Type": "application/json" },
            method: id ? "PUT" : "POST",
            types: asAsyncFormTypes(types.createOrUpdateDiscount.all),
            body: JSON.stringify(values),
        },
    });
};
