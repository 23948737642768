import type { Identifier } from "dnd-core";
import { makeStyles } from "tss-react/mui";
import React, { Fragment, ReactNode, Ref, useCallback, useRef } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Divider, Grid } from "@mui/material";
import { common } from "translations";
import { IconButton } from "components-ts/controls/buttons";
import { FormColumn } from "components-ts/forms";
import { Button } from "../buttons";
import { VirtualizedList, useVirtualizedParentResize } from "../virtualizations";

const useStyles = makeStyles()((theme) => ({
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    parent: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
    },
    children: {
        width: "100%",
    },
}));

export type ListProps = {
    children: React.ReactNode[];
    addItem?: () => void;
    addButtonText?: string;
    hideDivider?: boolean;
    noMarginInDivider?: boolean;
    canRemoveItem?: ((index: number) => boolean) | boolean;
    removeItem?: (index: number) => void;
    copyItem?: (index: number) => void;
    exportItem?: (index: number) => void;
} & (
    | {
          exportPartItem: (index: number) => void;
          exportPartLabel: string;
      }
    | {
          exportPartItem?: never;
          exportPartLabel?: never;
      }
) &
    (
        | {
              type: "draggable";
              moveItem: (fromIndex: number, toIndex: number) => void;
          }
        | {
              type?: "virtualize";
              moveItem?: never;
          }
    );

const DraggableItem: React.FC<{
    children: ReactNode;
    index: number;
    getItemButtons: (index: number, dragIconRef: Ref<HTMLDivElement>) => ReactNode;
    getItemDivider?: (index: number) => ReactNode;
    moveItem: (fromIndex: number, toIndex: number) => void;
}> = ({ children, index, getItemButtons, getItemDivider, moveItem }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [{ handlerId }, drop] = useDrop<
        {
            index: number;
            id: string;
            type: string;
        },
        void,
        { handlerId: Identifier | null }
    >(
        {
            accept: "ListItem",
            collect(monitor) {
                return {
                    handlerId: monitor.getHandlerId(),
                };
            },
            canDrop: () => {
                return true;
            },
            drop: (item) => {
                if (!ref.current) {
                    return;
                }
                const dragIndex = item.index;
                const hoverIndex = index;

                if (dragIndex === hoverIndex) {
                    return;
                }

                moveItem(dragIndex, hoverIndex);
            },
        },
        [index]
    );

    const [{ isDragging }, drag, preview] = useDrag({
        type: "ListItem",
        item: () => {
            return { index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0 : 1;
    preview(drop(ref));

    return (
        <Fragment key={index}>
            <Grid
                container
                justifyContent="space-between"
                alignItems="flex-start"
                direction="row"
                item
                key={index}
                style={{ opacity }}
            >
                <Grid
                    ref={ref}
                    data-handler-id={handlerId}
                    item
                    xs={Boolean(getItemButtons) ? 11 : 12}
                >
                    {children}
                </Grid>

                <Grid
                    item
                    xs={1}
                >
                    {getItemButtons(index, drag)}
                </Grid>
            </Grid>
            {getItemDivider && getItemDivider(index)}
        </Fragment>
    );
};

const List: React.FC<ListProps> = ({
    addItem,
    addButtonText,
    children,
    hideDivider = false,
    noMarginInDivider = false,
    canRemoveItem,
    removeItem,
    copyItem,
    exportItem,
    exportPartItem,
    exportPartLabel,
    type,
    moveItem,
}) => {
    const virtualizedParentResize = useVirtualizedParentResize();
    const { classes } = useStyles();

    const addButton = addItem !== undefined && (
        <Grid
            item
            style={{
                marginLeft: "auto",
                marginRight: "10px",
                marginBottom: "10px",
            }}
        >
            <Button
                onClick={() => {
                    addItem();
                    if (virtualizedParentResize) {
                        virtualizedParentResize();
                    }
                }}
                label={addButtonText ?? common.add}
            />
        </Grid>
    );

    const itemHasButtons =
        removeItem !== undefined || copyItem !== undefined || exportItem !== undefined || exportPartItem !== undefined || type === "draggable";
    const getItemButtons = useCallback(
        (index: number, dragIconRef?: Ref<HTMLDivElement>): ReactNode => {
            return itemHasButtons ? (
                <>
                    {exportPartItem && (
                        <IconButton
                            onClick={() => exportPartItem(index)}
                            icon="exportPart"
                            tooltip={exportPartLabel}
                            useWrapper={false}
                            showInline
                        />
                    )}

                    {exportItem && (
                        <IconButton
                            onClick={() => exportItem(index)}
                            icon="export"
                            tooltip="Eksportuj"
                            useWrapper={false}
                            showInline
                        />
                    )}

                    {copyItem && (
                        <IconButton
                            onClick={() => {
                                copyItem(index);
                                if (virtualizedParentResize) {
                                    virtualizedParentResize();
                                }
                            }}
                            icon="copy"
                            tooltip="Kopiuj"
                            useWrapper={false}
                            showInline
                        />
                    )}

                    {type === "draggable" && (
                        <IconButton
                            ref={dragIconRef}
                            onClick={() => {}}
                            icon="drag_handle"
                            tooltip="Złap i przesuń"
                            useWrapper={false}
                            showInline
                        />
                    )}

                    {removeItem && (
                        <IconButton
                            disabled={
                                canRemoveItem !== undefined ? (typeof canRemoveItem === "function" ? !canRemoveItem(index) : !canRemoveItem) : false
                            }
                            onClick={() => {
                                removeItem(index);
                                if (virtualizedParentResize) {
                                    virtualizedParentResize();
                                }
                            }}
                            icon="delete"
                            tooltip="Usuń"
                            useWrapper={false}
                            showInline
                        />
                    )}
                </>
            ) : (
                <></>
            );
        },
        [removeItem, copyItem, exportItem, exportPartItem]
    );
    const getItemDivider = (index: number) =>
        !hideDivider && children.length !== index + 1 ? (
            <Divider
                className={noMarginInDivider ? undefined : classes.divider}
                variant="fullWidth"
            />
        ) : undefined;

    if (type === "draggable") {
        return (
            <FormColumn>
                <DndProvider backend={HTML5Backend}>
                    {children.map((c: React.ReactNode, index: number) => {
                        return (
                            <DraggableItem
                                index={index}
                                getItemDivider={!hideDivider ? getItemDivider : undefined}
                                getItemButtons={getItemButtons}
                                moveItem={moveItem}
                            >
                                {c}
                            </DraggableItem>
                        );
                    })}
                </DndProvider>
                {addButton}
            </FormColumn>
        );
    }
    if (type === "virtualize") {
        return (
            <FormColumn>
                <VirtualizedList
                    children={children}
                    itemHasButtons={itemHasButtons}
                    getItemButtons={getItemButtons}
                    hideDivider={hideDivider}
                    getItemDivider={getItemDivider}
                />
                {addButton}
            </FormColumn>
        );
    } else {
        return (
            <FormColumn>
                {children.map((c: React.ReactNode, index: number) => {
                    return (
                        <Fragment key={index}>
                            <Grid
                                container
                                justifyContent="space-between"
                                alignItems="flex-start"
                                direction="row"
                                item
                                key={index}
                            >
                                <Grid
                                    item
                                    xs={itemHasButtons ? 11 : 12}
                                >
                                    {c}
                                </Grid>

                                <Grid
                                    item
                                    xs={1}
                                >
                                    {getItemButtons(index)}
                                </Grid>
                            </Grid>
                            {getItemDivider(index)}
                        </Fragment>
                    );
                })}

                {addButton}
            </FormColumn>
        );
    }
};

export default List;
