import { ObsoleteWarehouses, Warehouses } from "types/sharedEnums";
import FormSelect from "../FormSelect";

type FormWarehouseProps = {
    label: string;
    /**
     * default 'warehouse'
     */
    name?: string;
    readOnly?: boolean;
    multiple?: true;
    disableEmpty?: boolean;
};

const FormWarehouseSelect: React.FC<FormWarehouseProps> = ({ label, name = "warehouse", multiple, disableEmpty, readOnly }) => {
    return (
        <FormSelect
            name={name}
            label={label}
            items={Warehouses.filter((w) => readOnly || !ObsoleteWarehouses.includes(w))}
            disableEmpty={disableEmpty}
            readOnly={readOnly}
            multiple={multiple}
        />
    );
};

export default FormWarehouseSelect;
