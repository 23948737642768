import { Pool, determineEffectType } from "components/Controls/EffectForm/model";
import { promotionTypes } from "components/Controls/promotionTypes";
import { toDictionary } from "utils/extensions/arrayExtensions";
import { CartPredicate, Requirements } from "utils/models/CommerceForms";
import { VoucherDefinition } from "../vouchers/models";

export default class PromotionModel {
    constructor(formData = {}) {
        this.campaignName = formData.campaignName;
        this.divisions = formData.divisions;
        this.warehouses = formData.warehouses;
        this.validFrom = formData.validFrom;
        this.validTo = formData.validTo;
        this.name = formData.name;
        this.isActive = formData.isActive;
        this.isPositioned = formData.isPositioned;
        this.isPromoted = formData.isPromoted;
        this.id = formData.id;
        this.definition = new Definition(formData.definition);
        this.contentData = toDictionary(new PromotionContentData(formData.contentData, formData?.definition?.promotionType));
        this.activationInfo = formData.activationInfo;
        this.promotionValidation = formData.promotionValidation;
        this.isSystem = formData.isSystem;
    }
}

class DefaultContentData {
    constructor(contentData = {}) {
        this.html = contentData.html;
        this.info = contentData.info;
        this.text = contentData.text;
        this.logo = contentData.logo;
        this.logotyp = contentData.logotyp;
        this.labelOne = contentData.labelOne;
        this.labelTwo = contentData.labelTwo;
        this.zestaw = contentData.zestaw;
        this.show_gratis_in_basket = contentData.show_gratis_in_basket;
        this.potwierdzenie_text = contentData.potwierdzenie_text;
        this.potwierdzenie_bar_color = contentData.potwierdzenie_bar_color;
        this.potwierdzenie_font_color = contentData.potwierdzenie_font_color;
    }
}

class Definition {
    constructor(definition = {}) {
        this.promotionType = definition.promotionType;
        let progressiveQuantities = [];
        if (
            this.promotionType === promotionTypes.Progressive ||
            (!definition.promotionType && definition.effects?.some((e) => e.cartModifier?.progressiveModifiers))
        ) {
            const [firstPool = {}] = definition.pools || [];
            progressiveQuantities = firstPool.progressiveQuantities;
        }
        this.requirements = definition.requirements && new CartPredicate(definition.requirements);
        this.pools = definition.pools ? definition.pools.map((p) => new Pool(p)) : definition.pools;
        this.includeAlcohol = definition.includeAlcohol;
        this.includeTobacco = definition.includeTobacco;
        this.isCumulative = definition.isCumulative;
        this.cartActivationLimit = definition.cartActivationLimit;
        this.userActivationLimit = definition.userActivationLimit;
        this.promotionActivationLimit = definition.promotionActivationLimit;
        this.userActivationLimitExpirationPeriod = definition.userActivationLimitExpirationPeriod;
        this.onlyIdentityVerifiedFirstOrder = definition.onlyIdentityVerifiedFirstOrder;
        this.requiresConsent = definition.requiresConsent;
        this.effects = definition.effects
            ? definition.effects.map((e) => determineEffectType(e, definition.promotionType, progressiveQuantities))
            : definition.effects;
        this.minimumChoices = definition.minimumChoices;
        this.maximumChoices = definition.maximumChoices;
        this.userActivationLimitExpirationInterval = definition.userActivationLimitExpirationInterval;
    }
}

export class DiscountProduct {
    constructor(product) {
        this.productId = product.productId;
        const { discountValue, discountPercent, newPrice } = product.modifier;

        if (discountValue) {
            this.type = "discountValue";
            this.modifier = {
                discountValue,
            };
        } else if (discountPercent) {
            this.type = "discountPercent";
            this.modifier = {
                discountPercent,
            };
        } else if (newPrice) {
            this.type = "newPrice";
            this.modifier = {
                newPrice,
            };
        }
    }
}

export class GiftModel {
    constructor(obj) {
        this.validFrom = obj.validFrom;
        this.validTo = obj.validTo;
        this.name = obj.name;
        this.isActive = obj.isActive;
        this.choiceRequirements = obj.choiceRequirements && new CartPredicate(obj.choiceRequirements);
        this.retentionRequirements = obj.retentionRequirements && new Requirements(obj.retentionRequirements);
        this.awardRequirements = obj.awardRequirements && new Requirements(obj.awardRequirements);
        this.minumumChoiceCount = obj.minumumChoiceCount;
        this.maximumChoiceCount = obj.maximumChoiceCount;
        this.fallbackChoiceCount = obj.fallbackChoiceCount;
        this.contentData = obj.contentData && toDictionary(obj.contentData);
        this.isSystem = obj.isSystem;
    }
}

export class GiftChoiceModel {
    constructor(obj) {
        this.validFrom = obj.validFrom;
        this.validTo = obj.validTo;
        this.name = obj.name;
        this.isActive = obj.isActive;
        this.campaignName = obj.campaignName;
        this.activeChoiceGroup = obj.activeChoiceGroup;
        this.priority = obj.priority;
        this.reservationLimit = obj.reservationLimit;
        this.reservationMonthlyLimit = obj.reservationMonthlyLimit;
        this.contentData = obj.contentData && toDictionary(obj.contentData);
        this.definition = new GiftChoiceDefinition(obj.definition);
        this.activationInfo = obj.activationInfo;
        this.validation = obj.validation;
        this.isSystem = obj.isSystem;
        this.divisions = obj.divisions;
        this.isFallback = obj.isFallback;
        this.isSubbmitRequested = obj.isSubbmitRequested;
    }
}

class GiftChoiceDefinition {
    constructor(definition = {}) {
        this.requirements = new CartPredicate(definition.requirements);
        this.userActivationLimit = definition.userActivationLimit;
        this.userActivationLimitExpirationPeriod = definition.userActivationLimitExpirationPeriod;
        this.userActivationLimitExpirationInterval = definition.userActivationLimitExpirationInterval;
        this.effects = definition.effects ? definition.effects.map((e) => determineEffectType(e, definition.promotionType)) : definition.effects;
    }
}

export class RewardForm {
    constructor(formData = {}) {
        this.divisions = formData.divisions;
        this.validFrom = formData.validFrom;
        this.validTo = formData.validTo;
        this.name = formData.name;
        this.membershipPoints = formData.membershipPoints;
        this.priority = formData.priority;
        this.reservationLimit = formData.reservationLimit;
        this.reservationMonthlyLimit = formData.reservationMonthlyLimit;
        this.definition = new RewardDefinition(formData.definition);
        this.contentData = toDictionary(new RewardContentData(formData.contentData));
        this.isActive = formData.isActive;
    }
}

class RewardDefinition {
    constructor(definition = {}) {
        this.requirements = new Requirements(definition.requirements);
        this.effects = definition.effects ? definition.effects.map((e) => new RewardEffect(e)) : definition.effects;
        this.userActivationLimit = definition.userActivationLimit;
        this.userActivationLimitExpirationPeriod = definition.userActivationLimitExpirationPeriod;
        this.userActivationLimitExpirationInterval = definition.userActivationLimitExpirationInterval;
    }
}

export class RewardEffect {
    constructor(effect = {}) {
        this.voucher = new RewardVoucher(effect.voucher);
    }

    static default() {
        var effect = new RewardEffect();
        effect.voucher.definition.requirements = {
            user: {
                haveSegmentsConstraint: true,
                segments: [{ included: ["commerce:MembershipRewards"] }],
            },
        };

        return effect;
    }
}

class RewardVoucher {
    constructor(voucher = {}) {
        this.validFrom = voucher.validFrom;
        this.validTo = voucher.validTo;
        this.validForDays = voucher.validForDays;
        this.definition = new VoucherDefinition(voucher.definition);
        this.contentData = toDictionary(voucher.contentData);
        this.campaignName = voucher.campaignName;
    }
}

class RewardContentData extends DefaultContentData {
    constructor(contentData = {}) {
        super(contentData);
        this.headline_rewards = contentData.headline_rewards;
        this.promo_text_rewards = contentData.promo_text_rewards;
        this.headline_reward = contentData.headline_reward;
        this.button_reward = contentData.button_reward;
        this.description_reward = contentData.description_reward;
        this.realisation_rules_reward = contentData.realisation_rules_reward;
        this.info_reward = contentData.info_reward;
        this.button_take_reward = contentData.button_take_reward;
        this.button_close = contentData.button_close;
        this.button_close_pkt = contentData.button_close_pkt;
        this.title_reward_accept = contentData.title_reward_accept;
        this.description_reward_accept = contentData.description_reward_accept;
        this.title_reward_confirm = contentData.title_reward_confirm;
        this.description_reward_confirm = contentData.description_reward_confirm;
        this.button_close = contentData.button_close;
        this.button_coupon_transfer = contentData.button_coupon_transfer;
        this.image_url = contentData.image_url;
        this.tags = contentData.tags;
        this.sponsored_desc = contentData.sponsored_desc;
        this.sponsored_title = contentData.sponsored_title;
    }
}

class PromotionContentData extends DefaultContentData {
    constructor(contentData = {}, type) {
        super(contentData);
        this.headline_task = contentData.headline_task;
        this.subheadline_task = contentData.subheadline_task;
        this.description_task = contentData.description_task;
        this.info_task = contentData.info_task;
        this.button_task = contentData.button_task;
        this.image_url = contentData.image_url;
        this.img_task = contentData.img_task;
        if (type === promotionTypes.Membership) {
            this.tags = contentData.tags;
        }
    }
}
