import { CartPredicate, PromotionTypeForm, PromotionTypeSelect, UserActivationLimit } from "components/Controls";
import { FormField } from "components/Form";
import { FormSection } from "redux-form";
import { withFormName } from "utils/hoc";
import { useCommonTranslation, useFormValueSelector } from "utils/hooks";
import { Grid } from "@mui/material";
import { validators } from "../../validators";

const PromotionDefinition = ({ form, sectionPrefix, name = "definition", readOnly = false, isVoucherEmbedded = false }) => {
    const { t, common } = useCommonTranslation();
    const section = `${sectionPrefix ? `${sectionPrefix}.${name}` : name}`;
    const definition = useFormValueSelector(form, section);
    const { effects = [], promotionType } = definition;
    const isMembershipPromotion = promotionType == "Membership" || effects.some((x) => x.effectType === "membershipPoints");

    return (
        <>
            <FormSection name="definition">
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <FormField
                            name={"promotionActivationLimit"}
                            type={"number"}
                            label={t(common.promotionActivationLimit)}
                        />

                        <FormField
                            name={"cartActivationLimit"}
                            type={"number"}
                            label={t(common.cartActivationLimit)}
                            validate={validators.greaterThan0WhenNotEmpty}
                        />

                        <UserActivationLimit readOnly={readOnly} />
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <FormField
                            name="onlyIdentityVerifiedFirstOrder"
                            label={t(common.onlyIdentityVerifiedFirstOrder)}
                            type="boolean"
                        />
                        <FormField
                            name={"includeAlcohol"}
                            type={"boolean"}
                            label={t(common.includeAlcohol)}
                        />

                        <FormField
                            name={"includeTobacco"}
                            type={"boolean"}
                            label={t(common.includeTobacco)}
                        />

                        <FormField
                            name={"isCumulative"}
                            type={"boolean"}
                            label={t(common.isCumulative)}
                        />

                        <FormField
                            name={"requiresConsent"}
                            type={"boolean"}
                            label={t(common.requiresConsent)}
                        />
                    </Grid>

                    <PromotionTypeSelect />
                </Grid>

                <CartPredicate
                    name="requirements"
                    readOnly={readOnly}
                    requiredSegment={isMembershipPromotion}
                    showCartProducts
                    isVoucherEmbedded={isVoucherEmbedded}
                />
            </FormSection>

            <PromotionTypeForm effectValidation={validators.effectValidation} />
        </>
    );
};

export default withFormName(PromotionDefinition);
