import React, { createContext, useContext, useEffect } from "react";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Box, Grid } from "@mui/material";
import {
    GridActionsCellItem,
    GridActionsColDef,
    GridCallbackDetails,
    GridColDef,
    GridColumnVisibilityModel,
    GridPaginationModel,
    DataGrid as MuiDataGrid,
    gridClasses,
    useGridApiRef,
} from "@mui/x-data-grid";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import { GridApiCommunity, GridBaseColDef } from "@mui/x-data-grid/internals";
import { createId } from "@paralleldrive/cuid2";
import { DataGridColumn, DataGridProps } from "control-types";
import { Button } from "components-ts/controls";
import { Spacing } from "components-ts/view";

const initState = {
    pagination: {
        paginationModel: {
            pageSize: 50,
        },
    },
};

const pageSizes = [10, 20, 50, 100];
const serverPageSizes = [10, 25, 50, 100];
const sx = {
    "& .MuiDataGrid-columnHeaderTitle": {
        whiteSpace: "normal",
        lineHeight: "normal",
    },
    "& .MuiDataGrid-columnHeader": {
        // Forced to use important since overriding inline styles
        height: "unset !important",
    },
    "& .MuiDataGrid-columnHeaders": {
        // Forced to use important since overriding inline styles
        maxHeight: "168px !important",
        minHeight: "80px !important",
        backgroundColor: "#283593",
        color: "#FFFFFF",
    },
    "& .MuiDataGrid-columnHeader.a": {
        backgroundColor: "#283593",
        color: "#FFFFFF",
    },
    "& .MuiDataGrid-columnHeader.b": {
        backgroundColor: "#0e8e00",
        color: "#FFFFFF",
    },
    "& .MuiDataGrid-sortIcon": {
        color: "#FFFFFF",
    },
    "& .MuiDataGrid-menuIconButton": {
        color: "#FFFFFF",
    },
    "& .MuiDataGrid-filterIcon": {
        color: "#FFFFFF",
    },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
        color: "#FFFFFF",
        borderColor: "#FFFFFF",
    },
    [`& .${gridClasses.row}.odd`]: {
        backgroundColor: "#ececec",
    },
    "& ::-webkit-scrollbar": {
        width: "20px",
        height: "20px",
    },
};

const getRowClassName = (params: { indexRelativeToCurrentPage: number }) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd");

const DataGrid = <TValue extends { id: string }>({
    columns,
    rows,
    visibleColumnsKey,
    addItem,
    getRowActions,
    checkboxSelection,
    canCopyItemValues,
    copyButtonText,
    deleteButtonText,
    handleCopyValues,
    onRowEditStop,
    setSelectedRows,
    additionalButtons,
    autoHeight,
    handlePaginationChange,
    paginationModel,
    isRowSelectable,
    handleDeleteRows,
}: DataGridProps<TValue>): JSX.Element => {
    const apiRef = useGridApiRef();
    const onColumnVisibilityModelChange = React.useMemo(
        () => (_model: GridColumnVisibilityModel, _details: GridCallbackDetails) => {
            const state = apiRef.current.exportState();
            localStorage.setItem(visibleColumnsKey, JSON.stringify(state));
        },
        [visibleColumnsKey]
    );
    const [selection, setSelection] = useState<GridRowSelectionModel>([]);

    useEffect(() => {
        try {
            const stateJSON = localStorage.getItem(visibleColumnsKey);
            if (stateJSON) {
                apiRef.current.restoreState(JSON.parse(stateJSON));
            }
        } catch (e) {
            console.log(e);
        }
    }, []);

    const handleAdd = () => {
        if (!addItem) {
            return;
        }

        addItem(createId());
    };

    const isGridColDef = (column: DataGridColumn<TValue> | GridColDef<TValue>): column is GridColDef<TValue> => {
        return (column as GridColDef<TValue>).field !== undefined;
    };

    const cols: GridColDef<TValue>[] = columns.map((c) => {
        if (isGridColDef(c)) {
            return c;
        } else {
            return {
                field: c.name,
                headerName: c.label,
                width: c.width,
                editable: c.readOnly ? false : true,
                hideable: c.canHide ? true : false,
                headerClassName: c.alterStyle ? "b" : "a",
                renderCell: c.renderCell,
                renderEditCell: c.renderEditCell,
            } as GridBaseColDef<TValue>;
        }
    });

    if (getRowActions) {
        cols.push({
            field: "actions",
            type: "actions",
            headerName: "",
            width: 100,
            hideable: false,
            sortable: false,
            cellClassName: "actions",
            getActions: ({ id, row }: { id: string; row: TValue }) => {
                return getRowActions().map((ca) => (
                    <GridActionsCellItem
                        icon={ca.actionType === "delete" ? <DeleteIcon /> : <></>}
                        label={ca.actionType === "delete" ? "Delete" : ""}
                        onClick={() => ca.onClick(id, row)}
                        color="secondary"
                        disabled={ca.disabled}
                    />
                ));
            },
        } as GridActionsColDef<TValue>);
    }

    const handleSetSelection = (rows: GridRowSelectionModel) => {
        setSelection(rows);
        if (setSelectedRows) {
            setSelectedRows(rows);
        }
    };

    return (
        <>
            <Box sx={{ width: "100%" }}>
                <MuiDataGrid
                    autoHeight={autoHeight}
                    getRowHeight={() => "auto"}
                    editMode="row"
                    apiRef={apiRef}
                    rows={rows}
                    columns={cols}
                    initialState={initState}
                    pageSizeOptions={paginationModel ? serverPageSizes : pageSizes}
                    disableRowSelectionOnClick
                    showCellVerticalBorder
                    onColumnVisibilityModelChange={onColumnVisibilityModelChange}
                    getRowClassName={getRowClassName}
                    sx={!!autoHeight ? sx : { ...sx, maxHeight: "100vh" }}
                    checkboxSelection={checkboxSelection}
                    isRowSelectable={isRowSelectable}
                    onRowSelectionModelChange={handleSetSelection}
                    onRowEditStop={(params) => {
                        if (onRowEditStop) {
                            onRowEditStop(params.row);
                        }
                    }}
                    onPaginationModelChange={(model: GridPaginationModel) => {
                        handlePaginationChange && handlePaginationChange({ pageIndex: model.page + 1, pageSize: model.pageSize });
                    }}
                    paginationModel={
                        paginationModel
                            ? { pageSize: paginationModel?.pageSize ?? pageSizes[0], page: (paginationModel?.pageIndex ?? 1) - 1 }
                            : undefined
                    }
                    rowCount={paginationModel?.totalCount}
                    paginationMode={paginationModel ? "server" : "client"}
                />
            </Box>
            <Spacing spacing={2}>
                <Grid
                    container
                    direction="column"
                    justify-content="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                >
                    {addItem && (
                        <Grid
                            item
                            key="add"
                        >
                            <Button
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={handleAdd}
                                label="addRow"
                            />
                        </Grid>
                    )}
                    {canCopyItemValues && (
                        <Grid
                            item
                            key="copy"
                        >
                            <Button
                                color="primary"
                                onClick={() => {
                                    if (handleCopyValues) {
                                        handleCopyValues(selection, rows);
                                    }
                                }}
                                disabled={selection.length == 0}
                                label={copyButtonText || "copy"}
                                startIcon={<AutoAwesomeMotionIcon />}
                            />
                        </Grid>
                    )}
                    {handleDeleteRows && (
                        <Grid
                            item
                            key="delete"
                        >
                            <Button
                                color="primary"
                                onClick={() => {
                                    if (handleDeleteRows) {
                                        handleDeleteRows(selection, rows);
                                    }
                                }}
                                disabled={selection.length == 0}
                                label={deleteButtonText || "delete"}
                                startIcon={<DeleteForeverIcon />}
                            />
                        </Grid>
                    )}
                    {!!additionalButtons &&
                        additionalButtons.map((button, index) => (
                            <Grid
                                item
                                key={`button-${index}`}
                            >
                                {button}
                            </Grid>
                        ))}
                </Grid>
            </Spacing>
        </>
    );
};

export default DataGrid;

const DataGridContext = createContext<React.MutableRefObject<GridApiCommunity> | undefined>(undefined);

export const useDataGridApiContext = (): React.MutableRefObject<GridApiCommunity> => {
    const context = useContext(DataGridContext);
    if (!context) {
        throw new Error("useDataGridApiContext must be used within a DataGridApiProvider");
    }

    return context;
};

export const DataGridApiProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const apiRef = useGridApiRef();

    return <DataGridContext.Provider value={apiRef}>{children}</DataGridContext.Provider>;
};
