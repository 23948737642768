import { makeStyles } from "tss-react/mui";
import React, { forwardRef } from "react";
import { FormControlLabel, Switch as MuiSwitch } from "@mui/material";
import { SwitchProps } from "control-types";
import FormHelperText from "components-ts/controls/inputs/FormHelperText";

const useStyles = makeStyles()(() => ({
    label: {
        padding: 0,
        fontSize: "0.8rem",
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: "0.00938em",
    },
}));

const Switch: React.FC<SwitchProps> = forwardRef(({ value, label, error, required, onBlur, onChange, readOnly, name, size, key }, ref) => {
    const { classes } = useStyles();

    return (
        <>
            <FormControlLabel
                name={name}
                classes={{
                    label: classes.label,
                }}
                labelPlacement="end"
                label={label}
                required={required}
                control={
                    <MuiSwitch
                        size={size}
                        key={key}
                        onChange={(event, checked) => onChange(checked, event)}
                        onBlur={onBlur}
                        disabled={readOnly}
                        checked={value}
                        inputRef={ref}
                    />
                }
            />
            <FormHelperText error={error} />
        </>
    );
});

export default Switch;
