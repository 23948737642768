import { IconButton } from "components/Button";
import { FormField } from "components/Form";
import { decimalNormalize, integerNormalize } from "components/FormHelpers/ControlFormaters";
import { renderFieldOrText } from "components/FormHelpers/Controls";
import { Field, FormSection } from "redux-form";
import { actions } from "store/cache/products/actions";
import { cachedProductsSelector } from "store/offers/selectors";
import { makeStyles } from "tss-react/mui";
import { firstOrEmpty, toSelectList } from "utils/extensions";
import { withFormName } from "utils/hoc";
import { useChange, useCommonTranslation, useFormValueSelector } from "utils/hooks";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import classnames from "classnames";
import { validators } from "../../validators";

const useStyles = makeStyles()((theme) => ({
    "selected": {
        animation: `$highlight 1500ms ease-in-out`,
        border: `1px ${theme.palette.primary.main} solid`,
        borderRadius: theme.shape.borderRadius,
    },
    "item": {
        marginTop: 10,
    },
    "@keyframes highlight": {
        "0%": {
            background: theme.palette.primary.light,
        },
        "100%": {
            background: "inherit",
        },
    },
}));

const Product = ({ form, index, style, data }) => {
    const { classes } = useStyles();
    const { remove, field, searchedIndex } = data;
    const change = useChange(form);
    const cachedProducts = useSelector(cachedProductsSelector);
    const formField = `${field}.[${index}]`;
    const formProduct = useFormValueSelector(form, formField, {});
    const dispatch = useDispatch();
    const { t, common } = useCommonTranslation();
    const { productId, type } = formProduct;
    const { product = {} } = firstOrEmpty(cachedProducts, (x) => x.productId === productId);
    const { brand = "", name = "", imageUrl } = product;
    const item = {
        imageUrl,
        text: ` (${productId}) ${brand} - ${name}`,
    };

    return (
        <div style={style}>
            <FormSection
                name={formField}
                component="div"
            >
                <Grid
                    key={index}
                    item
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    className={classnames(searchedIndex === index ? classes.selected : "", classes.item)}
                >
                    <Grid
                        item
                        style={{ width: 600 }}
                    >
                        <Field
                            component={renderFieldOrText}
                            name={"productId"}
                            label={t(common.productId)}
                            type={"textWithImage"}
                            item={item}
                            onAdd={(value) => dispatch(actions.getProducts([value]))}
                            validate={validators.productId}
                        />
                    </Grid>
                    <Grid item>
                        <FormField
                            component={renderFieldOrText}
                            name={"type"}
                            label={t(common.modifier)}
                            type={"select"}
                            items={toSelectList(["discountPercent", "discountValue", "newPrice"], t)}
                            onChange={async (_, type) => {
                                change(`${formField}.modifier`, {
                                    [type]: 0,
                                });
                            }}
                            validate={validators.modifierType}
                        />
                    </Grid>
                    <FormSection name="modifier">
                        <Grid item>
                            <FormField
                                name={type}
                                component={renderFieldOrText}
                                type={"number"}
                                label={t(type)}
                                format={type === "discountPercent" ? integerNormalize : decimalNormalize}
                                validate={validators.discountPercent}
                            />
                        </Grid>
                    </FormSection>
                    <Grid item>
                        <IconButton
                            icon="delete"
                            onClick={() => remove(index)}
                        />
                    </Grid>
                </Grid>
            </FormSection>
        </div>
    );
};

export default React.memo(withFormName(Product));
