import { initForm, refundationFormSelector } from "store/vendors/refundations";
import { useCommonTranslation, useParams } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import VendorLayout from "views/vendors/components/VendorLayout";
import RefundationForm from "./RefundationForm";

const Refundation = () => {
    const { vendorId, refundationId } = useParams();
    const { t, common } = useCommonTranslation();
    const dispatch = useDispatch();
    const { isLoading, dateFrom, dateTo } = useSelector(refundationFormSelector);

    useEffect(() => {
        dispatch(initForm(vendorId, refundationId));
    }, [dispatch, refundationId, vendorId]);

    return (
        <VendorLayout
            headerText={t(common.refundationForm, { id: refundationId })}
            vendorId={vendorId}
            isLoading={isLoading}
            tabIndex={4}
            customTitle={`${vendorId} ${t(common.refundationForm)} ${
                dateFrom && dateTo ? `${moment(dateFrom).format("DD.MM.YYYY")}-${moment(dateTo).format("DD.MM.YYYY")}` : ""
            }`}
        >
            <RefundationForm
                vendorId={vendorId}
                handleDecision={() => {
                    dispatch(initForm(vendorId, refundationId));
                }}
                handleSave={() => {
                    dispatch(initForm(vendorId, refundationId));
                }}
            />
        </VendorLayout>
    );
};

export default Refundation;
