const apng = { extension: "apng", mimeType: "image/apng" };
const avif = { extension: "avif", mimeType: "image/avif" };
const gif = { extension: "gif", mimeType: "image/gif" };
const jpg = { extension: "jpg", mimeType: "image/jpeg" };
const jpeg = { extension: "jpeg", mimeType: "image/jpeg" };
const jfif = { extension: "jfif", mimeType: "image/jpeg" };
const pjpeg = { extension: "pjpeg", mimeType: "image/jpeg" };
const pjp = { extension: "pjp", mimeType: "image/jpeg" };
const png = { extension: "png", mimeType: "image/png" };
const svg = { extension: "svg", mimeType: "image/svg+xml" };
const bmp = { extension: "bmp", mimeType: "image/bmp" };
const ico = { extension: "ico", mimeType: "image/x-icon" };
const cur = { extension: "cur", mimeType: "image/x-icon" };
const tif = { extension: "tif", mimeType: "image/tiff" };
const tiff = { extension: "tiff", mimeType: "image/tiff" };
const webp = { extension: "webp", mimeType: "image/webp" };

const pdf = { extension: "pdf", mimeType: "application/pdf" };
const zip = { extension: "zip", mimeType: "application/zip" };
const txt = { extension: "txt", mimeType: "text/plain" };
const csv = { extension: "csv", mimeType: "application/csv" };
const doc = {
    extension: "doc",
    mimeType: "application/msword",
};
const docx = {
    extension: "docx",
    mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
};
const xls = {
    extension: "xls",
    mimeType: "application/ms-excel",
};
const xlsx = {
    extension: "xlsx",
    mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

let fileTypes = {
    apng,
    avif,
    gif,
    jpg,
    jpeg,
    jfif,
    pjpeg,
    pjp,
    png,
    svg,
    bmp,
    ico,
    cur,
    tif,
    tiff,
    webp,
    pdf,
    zip,
    txt,
    csv,
    doc,
    docx,
    xls,
    xlsx,
};

export default fileTypes;

export const ImageFiles = [gif, jpg, jpeg, jfif, pjpeg, pjp, png, bmp, ico, cur];
export const PdfFile = pdf;
export const ExcelFile = xlsx;
export const CsvFile = csv;
