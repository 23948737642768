import { FormField } from "components/Form";
import { integerNormalize } from "components/FormHelpers/ControlFormaters";
import { toSelectList } from "utils/extensions";
import { useCommonTranslation } from "utils/hooks";
import { isInteger } from "utils/numberExtensions";
import Validator, { greaterThan, mustBeNumber, notEmpty, notEmptyValidator } from "utils/validators/basic";
import { Grid } from "@mui/material";
import moment from "moment";
import { get, isEmpty } from "lodash";
import { tValidation, validation } from "utils-ts/validations/translation";

const userActivationLimit = (value) =>
    new Validator(value)
        .must(mustBeNumber, tValidation(validation.mustBeNumber))
        .must(isInteger, tValidation(validation.mustBeInteger))
        .must(greaterThan(0), tValidation(validation.greaterThan, { number: 0 }))
        .allWhen(notEmpty(value))
        .validate();

const userActivationLimitExpirationPeriod = (_, form, __, path) => {
    const { userActivationLimit, userActivationLimitExpirationInterval, userActivationLimitExpirationPeriod } =
        get(form, path.substring(0, path.lastIndexOf("."))) || {};

    return new Validator(userActivationLimitExpirationPeriod)
        .must(isEmpty, tValidation(validation.choseOneUserActivationLimitExpiration))
        .when(notEmpty(userActivationLimitExpirationInterval))
        .must(() => notEmpty(userActivationLimitExpirationInterval || userActivationLimitExpirationPeriod), tValidation(validation.atLeastOneField))
        .when(notEmpty(userActivationLimit))
        .must((value) => {
            return !value.endsWith(".");
        }, tValidation(validation.mustBeNumber))
        .when(notEmpty(userActivationLimitExpirationPeriod))
        .validate();
};

const timeBetween0and365 = (value) => {
    value = moment.duration(value);
    return new Validator(value)
        .must(
            (value) => value >= moment.duration(0, "days") && value <= moment.duration(365, "days"),
            tValidation(validation.inRange, { min: 0, max: 365 })
        )
        .when(notEmpty(value))
        .validate();
};

const userActivationLimitExpirationInterval = (_, form, __, path) => {
    const { userActivationLimit, userActivationLimitExpirationInterval, userActivationLimitExpirationPeriod } =
        get(form, path.substring(0, path.lastIndexOf("."))) || {};

    return new Validator(userActivationLimitExpirationInterval)
        .must(isEmpty, tValidation(validation.choseOneUserActivationLimitExpiration))
        .when(notEmpty(userActivationLimitExpirationPeriod))
        .must(() => notEmpty(userActivationLimitExpirationInterval || userActivationLimitExpirationPeriod), tValidation(validation.atLeastOneField))
        .when(notEmpty(userActivationLimit))
        .validate();
};

const userActivationLimitExpirationIntervals = ["Daily", "Weekly", "Monthly", "Quarterly", "Yearly"];

const UserActivationLimit = ({ required, readOnly }) => {
    const { t, common } = useCommonTranslation();

    return (
        <Grid
            container
            direction="row"
        >
            <FormField
                name={"userActivationLimit"}
                formatDisplay={integerNormalize}
                type={"number"}
                label={tValidation(common.userActivationLimit, {}, "common")}
                validate={[required ? notEmptyValidator : undefined, userActivationLimit].filter(Boolean)}
                readOnly={readOnly}
            />

            <FormField
                name="userActivationLimitExpirationPeriod"
                label={tValidation(common.userActivationLimitExpirationPeriod, {}, "common")}
                type="timeSpan"
                validate={[userActivationLimitExpirationPeriod, timeBetween0and365]}
                readOnly={readOnly}
            />

            <FormField
                name="userActivationLimitExpirationInterval"
                type={"select"}
                label={tValidation(common.userActivationLimitExpirationInterval, {}, "common")}
                items={toSelectList(userActivationLimitExpirationIntervals, t)}
                validate={userActivationLimitExpirationInterval}
                readOnly={readOnly}
            />
        </Grid>
    );
};

export default UserActivationLimit;
