import { PromotionDocument, PromotionSearchParameters, PromotionSearchTag, PromotionType } from "Commerce-Offer";
import moment from "moment";
import { api } from "api";
import { Divisions, Warehouses } from "types/sharedEnums";
import { useColumns, useFacets, usePush, useTranslation } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { QueryTable } from "components-ts/tables";
import { View } from "components-ts/view";

const PromotionSearch: React.FC = () => {
    const { pushToForm } = usePush();
    const { t } = useTranslation("common");

    const columns = useColumns<PromotionDocument>(() => [
        {
            property: "name",
            label: "name",
        },
        {
            property: "campaignName",
            label: "campaignName",
        },
        {
            property: "validFrom",
            label: "validFrom",
            as: "date",
        },
        {
            property: "validTo",
            label: "validTo",
            as: "date",
        },
        {
            property: "isActive",
            label: "active",
            as: "boolean",
            filtrable: true,
            transformFilterValue: (value) => (value ? true : undefined),
        },
        {
            property: "isPositioned",
            label: "isPositioned",
            as: "boolean",
            filtrable: true,
            transformFilterValue: (value) => (value ? true : undefined),
        },
        {
            property: "isPromoted",
            label: "isPromoted",
            as: "boolean",
            filtrable: true,
            transformFilterValue: (value) => (value ? true : undefined),
        },
        {
            property: "createdAt",
            label: "createdAt",
            as: "date",
        },
        {
            property: "updatedAt",
            label: "updatedAt",
            as: "date",
        },
        {
            actionType: "preview",
            onClick: (item) => pushToForm(Paths.Offers.PromotionForm, item.id, false),
        },
        {
            actionType: "copy",
            onClick: (item) => pushToForm(Paths.Offers.PromotionForm, item.id, true),
        },
    ]);

    const types: PromotionType[] = [
        "Membership",
        "Delivery",
        "Gratis",
        "Discount",
        "Pack",
        "Pool",
        "Pair",
        "Progressive",
        "Sampling",
        "DoublePrice",
        "Packaging",
        "GratisPicker",
        "Segment",
        "ChosenProduct",
        "PackGratis",
        "PoolGratis",
    ];

    const facets = useFacets<PromotionSearchParameters>(() => [
        {
            property: "validAt",
            label: { resource: "common:validAt" },
            as: "dateTime",
        },
        {
            property: "warehouse",
            label: { resource: "common:warehouse" },
            as: "checkboxList",
            useAndOperator: true,
            items: Warehouses,
            addAllCount: true,
        },
        {
            property: "division",
            label: { resource: "common:division" },
            as: "checkboxList",
            useAndOperator: true,
            items: Divisions,
            addAllCount: true,
        },
        {
            property: "promotionType",
            label: { resource: "common:promotionType" },
            as: "checkboxList",
            items: types.map((type) => ({
                value: type,
                name: t(type),
            })),
        },
        {
            property: "campaignName",
            label: { resource: "common:campaignName" },
            as: "autocomplete",
        },
    ]);

    return (
        <View
            headerText={{ resource: "common:promotions" }}
            isMainView
        >
            <QueryTable
                queryHook={api.commerce.offers.promotions.useFindPromotions}
                formPath={Paths.Offers.PromotionForm}
                columns={columns}
                defaultParams={{ validAt: moment(), isActive: true }}
                hideToggle
                facets={facets}
                facetPosition="top"
                columnFilterToParams={(property, value, params) => {
                    if (property === "isActive") {
                        return {
                            tag: value
                                ? ["IsActive" as PromotionSearchTag, ...(params.tag || [])]
                                : (params.tag || []).filter((t) => t !== "IsActive"),
                            validAt: value ? moment() : undefined,
                        };
                    } else if (property === "isPositioned") {
                        return {
                            tag: value
                                ? ["IsPositioned" as PromotionSearchTag, ...(params.tag || [])]
                                : (params.tag || []).filter((t) => t !== "IsPositioned"),
                        };
                    } else if (property === "isPromoted") {
                        return {
                            tag: value
                                ? ["IsPromoted" as PromotionSearchTag, ...(params.tag || [])]
                                : (params.tag || []).filter((t) => t !== "IsPromoted"),
                        };
                    } else {
                        return {};
                    }
                }}
            />
        </View>
    );
};

export default PromotionSearch;
