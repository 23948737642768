import { commerce, manager } from "store/actionTypePrefixes";
import { AsyncTypes, createAction } from "utils/extensions";
import { asSilientError } from "utils/extensions";

export const types = {
    initDiscountForm: createAction("INIT_DISCOUNT_FORM", manager),
    getDiscount: new AsyncTypes("GET_DISCOUNT", commerce),
    getDiscountValidation: new AsyncTypes("GET_DISCOUNT_VALIDATION", commerce, [asSilientError]),
    createOrUpdateDiscount: new AsyncTypes("CREATE_OR_UPDATE_DISCOUNT", commerce),
};
