import defaultListState from "store/defaultListState";
import types from "./types";

const initialState = {
    ...defaultListState,
    filterValues: {},
    suppliers: [],
    ean: [],
    productIds: [],
    selectedSupplier: undefined,
    brandsFileInProgress: false,
    productsFileInProgress: false,
    fileDownloadInProgress: false,
};

export const initProducts = (state = initialState, action) => {
    state = state || initialState;
    switch (action.type) {
        case types.getUserSuppliers.request:
        case types.getUserSuppliers.failure:
            return { ...state, suppliers: [] };
        case types.getUserSuppliers.success:
            return {
                ...state,
                suppliers: action.payload,
                selectedSupplier: action.payload && action.payload.length > 0 ? action.payload[0].vendorId : undefined,
            };
        case types.getProductInitEans.request:
        case types.getProductInitEans.failure:
            return { ...state, eans: [] };
        case types.getProductInitEans.success:
            return {
                ...state,
                eans: action.payload,
            };
        case types.getProductInitProductIds.request:
        case types.getProductInitProductIds.failure:
            return { ...state, productIds: [] };
        case types.getProductInitProductIds.success:
            return {
                ...state,
                productIds: action.payload,
            };
        case types.initProductsSelectSupplier:
            return { ...state, selectedSupplier: action.payload };
        case types.updateInitProductsFilter:
            return { ...state, filterValues: action.payload };
        case types.initProductsGetPage.request:
            return { ...state, isLoading: true };
        case types.initProductsGetPage.success:
            return {
                ...state,
                items: action.payload.items.map((x) => {
                    const { productInitId, state, ean, productId, vendorId, categoryManager, initedByTrade, creationDate, statusChangedAt } = x;
                    return {
                        ean,
                        productInitId,
                        vendorId,
                        productId,
                        categoryManager,
                        initedByTrade,
                        state,
                        creationDate,
                        statusChangedAt,
                    };
                }),
                pageIndex: action.payload.pageIndex,
                pageSize: action.payload.pageSize,
                totalCount: action.payload.totalCount,
                isLoading: false,
            };
        case types.initProductsGetPage.failure:
            return { ...state };
        case types.downloadInitProductsBrandsFile.request:
            return { ...state, brandsFileInProgress: true, fileDownloadInProgress: true };
        case types.downloadInitProductsBrandsFile.failure:
        case types.downloadInitProductsBrandsFile.success:
            return {
                ...state,
                brandsFileInProgress: false,
                fileDownloadInProgress: state.productsFileInProgress,
            };
        case types.downloadInitProductsFile.request:
            return { ...state, productsFileInProgress: true, fileDownloadInProgress: true };
        case types.downloadInitProductsFile.failure:
        case types.downloadInitProductsFile.success:
            return {
                ...state,
                productsFileInProgress: false,
                fileDownloadInProgress: state.brandsFileInProgress,
            };
        default:
            return state;
    }
};
