import { DiscountSearchParameters, DiscountSearchResult } from "Commerce-Offer";
import { useGetPagedQuery } from "api/hooks";

const useFindDiscounts = (
    params: DiscountSearchParameters = {
        pageIndex: 1,
        pageSize: 25,
        includeFacets: true,
    }
) => {
    return useGetPagedQuery<DiscountSearchResult, DiscountSearchParameters>({
        app: "commerce",
        url: "api/v1/offer/products/discounts/query",
        queryParams: params,
    });
};

export default {
    useFindDiscounts,
};
