import GridContainer from "components/Grid/GridContainer";
import TextWithLabel from "components/Text/TextWithLabel";
import { formatTypes } from "utils/formating";
import { useCommonTranslation } from "utils/hooks";
import React from "react";
import { Grid } from "@mui/material";
import { Layout } from "components";

const field = (label, value, format, options) => {
    return {
        label,
        value,
        format,
        options,
    };
};

const DiscountValidation = ({ discountValidation = { warehouses: [] } }) => {
    const { warehouses } = discountValidation;
    const { t, common } = useCommonTranslation();
    const warehouseProblems = warehouses
        .filter((x) => Boolean(x.productProblems))
        .map((i) => ({
            warehouse: i.warehouse,
            productProblems: field(
                common.productProblems,
                i.products.map((p) => {
                    const productPrices =
                        p.lowPrice != null && p.newPrice != null ? " " + t("newPriceVsLowPrice", { lowPrice: p.lowPrice, newPrice: p.newPrice }) : "";
                    return `${p.productId}: ${p.problems.map((pp) => t(pp)).join(", ") + productPrices}`;
                }),
                formatTypes.text
            ),
        }));

    return (
        <Layout headerText={t(common.discountValidation)}>
            <Grid
                container
                direction="row"
            >
                {warehouseProblems.map((w) => (
                    <Grid
                        item
                        xs={6}
                        key={w.warehouse}
                    >
                        <GridContainer
                            iconName="location_city"
                            title={w.warehouse}
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <TextWithLabel
                                unselectableLabel
                                {...w.productProblems}
                            />
                        </GridContainer>
                    </Grid>
                ))}
            </Grid>
        </Layout>
    );
};

export default DiscountValidation;
