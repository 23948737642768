import { BaseValueValidators, ExtendedRuleValidators, ExtendedRuleValidatorsAndConditionExtensions, ExtendedValidators } from "extended-validator";
import { RuleValidators, ValidationErrors, Validator } from "fluentvalidation-ts";
import moment from "moment";
import { isEmpty } from "lodash";
import { dateToString, isDate } from "utils-ts/functions/dateUtils";
import { tValidation, validation } from "./translation";

class ProxyValidator<TModel> extends Validator<TModel> {
    constructor() {
        super();
    }

    public ruleForEach2<
        TPropertyName extends keyof TModel,
        TValue extends TModel[TPropertyName] extends (infer TEachValue)[] | readonly (infer TEachValue)[] | null | undefined
            ? TModel[TPropertyName] & (TEachValue[] | readonly TEachValue[] | null | undefined)
            : never,
    >(propertyName: TModel[TPropertyName] extends unknown[] | readonly unknown[] | null | undefined ? TPropertyName : never) {
        return this.ruleForEach<TPropertyName, TValue>(propertyName);
    }

    public ruleFor2<TPropertyName extends keyof TModel, TValue extends TModel[TPropertyName]>(propertyName: TPropertyName) {
        return this.ruleFor<TPropertyName, TValue>(propertyName);
    }

    public validate2(value: TModel) {
        return this.validate(value);
    }
}

type ArrayPropertyNames<T> = {
    [K in keyof T]: T[K] extends object | null | undefined ? (T[K] extends unknown[] | readonly unknown[] | null | undefined ? K : never) : never;
}[keyof T];

export class ExtendedValidator<TModel> {
    private baseValidator = new ProxyValidator<TModel>();
    private conditionalValidators = new Array<{
        condition: (model: TModel) => boolean;
        validators: (model?: TModel) => Array<ExtendedRuleValidatorsAndConditionExtensions<TModel, TModel[keyof TModel]>>;
    }>();

    private isValueEmpty = (value: unknown): boolean => {
        return (
            value === null ||
            value === undefined ||
            typeof value === "undefined" ||
            (Array.isArray(value) && value.length === 0) ||
            (typeof value === "string" && value.replace(/\s/g, "").length === 0)
        );
    };

    validate = (model: TModel): ValidationErrors<TModel> => {
        if (this.conditionalValidators.length > 0) {
            this.conditionalValidators.forEach((o) => {
                if (o.condition(model)) {
                    o.validators().forEach((r) => r.when(o.condition, "AppliesToAllValidators"));
                }
            });
        }

        return this.baseValidator.validate2(model);
    };

    atLeastOneOfFieldNotEmpty = <TPropertyName extends keyof TModel, TValue extends TModel[TPropertyName]>(propertyNames: TPropertyName[]): void => {
        if (propertyNames === null || propertyNames === undefined || propertyNames.length === 0) {
            return;
        }

        propertyNames.forEach((property) => {
            this.baseValidator
                .ruleFor2<TPropertyName, TValue>(property)
                .must({ predicate: (val) => !this.isValueEmpty(val), message: () => tValidation(validation.atLeastOneField) })
                .when((model) => {
                    return propertyNames.filter((p) => p !== property).every((p) => this.isValueEmpty(model[p]));
                });
        });
    };

    when = (
        condition: (model: TModel) => boolean,
        validators: (model?: TModel) => Array<ExtendedRuleValidatorsAndConditionExtensions<TModel, TModel[keyof TModel]>>
    ) => {
        this.conditionalValidators.push({ condition, validators });
    };

    extendRuleValidators = <TPropertyName extends keyof TModel, TValue extends TModel[TPropertyName]>(
        propertyName: TPropertyName,
        base: RuleValidators<TModel, TValue>
    ): ExtendedRuleValidators<TModel, TValue> => {
        //Extend BaseValueValidators
        let baseResult = Object.assign({
            notNull: () => {
                const conditionals = this.extendValidationResult(
                    propertyName,
                    baseResult,
                    extendedValidators,
                    base.must({
                        predicate: (val) => !this.isValueEmpty(val),
                        message: () => tValidation(validation.notEmpty),
                    })
                );

                return {
                    ...baseResult,
                    ...extendedValidators,
                    ...conditionals,
                };
            },
            null: () => {
                const conditionals = this.extendValidationResult(
                    propertyName,
                    baseResult,
                    extendedValidators,
                    base.must({
                        predicate: (val) => this.isValueEmpty(val),
                        message: () => tValidation(validation.notEmpty),
                    })
                );

                return {
                    ...baseResult,
                    ...extendedValidators,
                    ...conditionals,
                };
            },
            notEqual: (forbiddenValue: TValue) => {
                const conditionals = this.extendValidationResult(
                    propertyName,
                    baseResult,
                    extendedValidators,
                    base.notEqual(forbiddenValue).withMessage(tValidation(validation.mustBeNotEqaul, { value: forbiddenValue?.toString() }))
                );

                return {
                    ...baseResult,
                    ...extendedValidators,
                    ...conditionals,
                };
            },
            equal: (requiredValue: TValue) => {
                const conditionals = this.extendValidationResult(
                    propertyName,
                    baseResult,
                    extendedValidators,
                    base.equal(requiredValue).withMessage(tValidation(validation.mustBeEqual, { value: requiredValue?.toString() }))
                );

                return {
                    ...baseResult,
                    ...extendedValidators,
                    ...conditionals,
                };
            },
            must: (
                definition:
                    | ((value: TModel[TPropertyName], model: TModel) => boolean)
                    | {
                          predicate: (value: TModel[TPropertyName], model: TModel) => boolean;
                          message: string | ((value: TModel[TPropertyName], model: TModel) => string);
                      }
                    | (
                          | ((value: TModel[TPropertyName], model: TModel) => boolean)
                          | {
                                predicate: (value: TModel[TPropertyName], model: TModel) => boolean;
                                message: string | ((value: TModel[TPropertyName], model: TModel) => string);
                            }
                      )[]
            ) => {
                const { when, unless, withMessage } = base.must(definition);
                const conditionalsWithMessage = this.getConditionalsWithMessage(
                    propertyName,
                    baseResult,
                    extendedValidators,
                    when,
                    unless,
                    withMessage
                );

                return {
                    ...baseResult,
                    ...extendedValidators,
                    ...conditionalsWithMessage,
                };
            },
        }) as BaseValueValidators<TModel, TValue>;

        //Extend StringValueValidators
        if ("notEmpty" in base) {
            baseResult = {
                ...baseResult,
                notEmpty: () =>
                    this.extendValidationResult<TPropertyName, TValue>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.notEmpty().withMessage(tValidation(validation.notEmpty)).notNull().withMessage(tValidation(validation.notEmpty))
                    ),
                length: (minLength: number, maxLength: number) =>
                    this.extendValidationResult<TPropertyName, TValue>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.length(minLength, maxLength).withMessage(tValidation(validation.inRange, { min: minLength, max: maxLength }))
                    ),
                maxLength: (maxLength: number) =>
                    this.extendValidationResult<TPropertyName, TValue>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.maxLength(maxLength).withMessage(tValidation(validation.maxLength, { max: maxLength }))
                    ),
                minLength: (minLength: number) =>
                    this.extendValidationResult<TPropertyName, TValue>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.minLength(minLength).withMessage(tValidation(validation.minLength, { min: minLength }))
                    ),
                matches: (pattern: RegExp) =>
                    this.extendValidationResult<TPropertyName, TValue>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.matches(pattern).withMessage(tValidation(validation.formatRegex))
                    ),
                emailAddress: () =>
                    this.extendValidationResult<TPropertyName, TValue>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.emailAddress().withMessage(tValidation(validation.email))
                    ),
                identity: () =>
                    this.extendValidationResult<TPropertyName, TValue>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.length(1, 100).withMessage(tValidation(validation.inRange, { min: 1, max: 100 }))
                    ),
                exactLength: (exactLength: number) =>
                    this.extendValidationResult<TPropertyName, TValue>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.length(exactLength, exactLength).withMessage(tValidation(validation.length, { number: exactLength }))
                    ),
                postcode: () =>
                    this.extendValidationResult<TPropertyName, TValue>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base
                            .notEmpty()
                            .withMessage(tValidation(validation.notEmpty))
                            .notNull()
                            .withMessage(tValidation(validation.notEmpty))
                            .length(6, 6)
                            .withMessage(tValidation(validation.length, { number: 6 }))
                            .matches(/^[0-9]{2}-[0-9]{3}$/)
                            .withMessage(tValidation(validation.formatRegex))
                    ),
                postcodeMask: () =>
                    this.extendValidationResult(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base
                            .notEmpty()
                            .withMessage(tValidation(validation.notEmpty))
                            .notNull()
                            .withMessage(tValidation(validation.notEmpty))
                            .length(6, 6)
                            .withMessage(tValidation(validation.length, { number: 6 }))
                            .matches(
                                /^(([0-9]{2}-[0-9]{3})|([0-9]{2}-[0-9]{2}[\*]{1})|([0-9]{2}-[0-9]{1}[\*]{2})|([0-9]{2}-[\*]{3})|([0-9]{1}[\*]{1}-[\*]{3})|([\*]{2}-[\*]{3}))$/
                            )
                            .withMessage(tValidation(validation.formatRegex))
                    ),
            };
        }

        //Extend NumberValueValidators
        if ("lessThan" in base) {
            baseResult = {
                ...baseResult,
                lessThan: (threshold: number) =>
                    this.extendValidationResult<TPropertyName, TValue>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.lessThan(threshold).withMessage(tValidation(validation.lessThan, { value: threshold }))
                    ),
                lessThanOrEqualTo: (threshold: number) =>
                    this.extendValidationResult<TPropertyName, TValue>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.lessThanOrEqualTo(threshold).withMessage(tValidation(validation.lessOrEqualsThan, { value: threshold }))
                    ),
                greaterThan: (threshold: number) =>
                    this.extendValidationResult<TPropertyName, TValue>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.greaterThan(threshold).withMessage(tValidation(validation.greaterThan, { number: threshold }))
                    ),
                greaterThanOrEqualTo: (threshold: number) =>
                    this.extendValidationResult<TPropertyName, TValue>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.greaterThanOrEqualTo(threshold).withMessage(tValidation(validation.greaterOrEqualsThan, { number: threshold }))
                    ),
                exclusiveBetween: (lowerBound: number, upperBound: number) =>
                    this.extendValidationResult<TPropertyName, TValue>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base
                            .exclusiveBetween(lowerBound, upperBound)
                            .withMessage(tValidation(validation.between, { min: lowerBound, max: upperBound }))
                    ),
                inclusiveBetween: (lowerBound: number, upperBound: number) =>
                    this.extendValidationResult<TPropertyName, TValue>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base
                            .inclusiveBetween(lowerBound, upperBound)
                            .withMessage(tValidation(validation.between, { min: lowerBound, max: upperBound }))
                    ),
                scalePrecision: (precision: number, scale: number) =>
                    this.extendValidationResult<TPropertyName, TValue>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.scalePrecision(precision, scale).withMessage(tValidation(validation.mustHaveLessDecimalCount))
                    ),
            };
        }

        //Extend ObjectValueValidators
        if ("setValidator" in base) {
            baseResult = {
                ...baseResult,
                setValidator: (
                    validator:
                        | ((model: TModel) => {
                              validate: (
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  model: TValue extends null | undefined ? any : TValue
                              ) => ValidationErrors<TValue extends null | undefined ? unknown : TValue>;
                          })
                        | {
                              validate: (
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  model: TValue extends null | undefined ? any : TValue
                              ) => ValidationErrors<TValue extends null | undefined ? unknown : TValue>;
                          }
                ) =>
                    this.extendValidationResult(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.setValidator((model) => {
                            const val = typeof validator === "function" ? validator(model) : validator;
                            return val as unknown as {
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                validate: (model: any) => ValidationErrors<any>;
                            };
                        })
                    ),
            };
        }

        const extendMustValidatorResult = (
            predicate: (value: TValue, model: TModel) => boolean,
            message: string | ((value: TValue, model: TModel) => string)
        ) => this.extendValidationResult<TPropertyName, TValue>(propertyName, baseResult, extendedValidators, base.must({ predicate, message }));

        const greaterThanOrEqualTo = (compareToProperty: keyof TModel | number) => {
            return extendMustValidatorResult(
                (val, model) => {
                    if (typeof compareToProperty === "number") {
                        return val >= compareToProperty;
                    }

                    const compareTo = model[compareToProperty];
                    if (val === undefined || val === null || val === "" || compareTo === undefined || compareTo === null || compareTo === "") {
                        return true;
                    }

                    if (typeof val !== typeof model[compareToProperty]) {
                        throw Error("Comparing diffrent types");
                    }

                    if (typeof val === "number" || typeof val === "bigint" || typeof val === "boolean") {
                        return val >= compareTo;
                    } else if (typeof val === "string") {
                        return val >= compareTo;
                    } else if (moment.isMoment(val) && moment.isMoment(compareTo)) {
                        return val.isSameOrAfter(compareTo);
                    } else if (isDate(val) && isDate(compareTo)) {
                        return moment(val).isSameOrAfter(moment(compareTo));
                    }

                    return false;
                },
                (_val, model) => {
                    if (typeof compareToProperty === "number") {
                        return tValidation(validation.greaterOrEqualsThan, { number: compareToProperty?.toString() });
                    }

                    const compareTo = model[compareToProperty];
                    if (moment.isMoment(compareTo)) {
                        return tValidation(validation.greaterOrEqualsDateThan, { date: compareTo.format("YYYY-MM-DD HH:mm") });
                    } else if (isDate(compareTo)) {
                        return tValidation(validation.greaterOrEqualsDateThan, { date: dateToString(compareTo) });
                    }

                    return tValidation(validation.greaterOrEqualsThan, { number: compareTo?.toString() });
                }
            );
        };
        const greaterThan = (compareToProperty: keyof TModel | number) => {
            return extendMustValidatorResult(
                (val, model) => {
                    if (typeof compareToProperty === "number") {
                        return val > compareToProperty;
                    }

                    const compareTo = model[compareToProperty];
                    if (this.isValueEmpty(val) || this.isValueEmpty(compareTo)) {
                        return true;
                    }

                    if (typeof val !== typeof model[compareToProperty]) {
                        throw Error("Comparing diffrent types");
                    }

                    if (typeof val === "number" || typeof val === "bigint" || typeof val === "boolean") {
                        return val > compareTo;
                    } else if (typeof val === "string") {
                        return val > compareTo;
                    } else if (moment.isMoment(val) && moment.isMoment(compareTo)) {
                        return val.isAfter(compareTo);
                    } else if (isDate(val) && isDate(compareTo)) {
                        return moment(val).isAfter(moment(compareTo));
                    }

                    return false;
                },
                (_val, model) => {
                    if (typeof compareToProperty === "number") {
                        return tValidation(validation.greaterThan, { number: compareToProperty?.toString() });
                    }

                    const compareTo = model[compareToProperty];
                    if (moment.isMoment(compareTo)) {
                        return tValidation(validation.greaterDateThan, { date: compareTo.format("YYYY-MM-DD HH:mm") });
                    } else if (isDate(compareTo)) {
                        return tValidation(validation.greaterDateThan, { date: dateToString(compareTo) });
                    }

                    return tValidation(validation.greaterThan, { number: compareTo?.toString() });
                }
            );
        };
        const lessThanOrEqualTo = (compareToProperty: keyof TModel | number) => {
            return extendMustValidatorResult(
                (val, model) => {
                    if (typeof compareToProperty === "number") {
                        return val <= compareToProperty;
                    }

                    const compareTo = model[compareToProperty];
                    if (this.isValueEmpty(val) || this.isValueEmpty(compareTo)) {
                        return true;
                    }

                    if (typeof val !== typeof model[compareToProperty]) {
                        throw Error("Comparing diffrent types");
                    }

                    if (typeof val === "number" || typeof val === "bigint" || typeof val === "boolean") {
                        return val <= compareTo;
                    } else if (typeof val === "string") {
                        return val <= compareTo;
                    } else if (moment.isMoment(val) && moment.isMoment(compareTo)) {
                        return val.isSameOrBefore(compareTo);
                    } else if (isDate(val) && isDate(compareTo)) {
                        return moment(val).isSameOrBefore(moment(compareTo));
                    }

                    return false;
                },
                (_val, model) => {
                    if (typeof compareToProperty === "number") {
                        return tValidation(validation.lessOrEqualsThan, { number: compareToProperty?.toString() });
                    }

                    const compareTo = model[compareToProperty];
                    if (moment.isMoment(compareTo)) {
                        return tValidation(validation.lessOrEqualsDateThan, { date: compareTo.format("YYYY-MM-DD HH:mm") });
                    } else if (isDate(compareTo)) {
                        return tValidation(validation.lessOrEqualsDateThan, { date: dateToString(compareTo) });
                    }

                    return tValidation(validation.lessOrEqualsThan, { number: compareTo?.toString() });
                }
            );
        };
        const lessThan = (compareToProperty: keyof TModel | number) => {
            return extendMustValidatorResult(
                (val, model) => {
                    if (typeof compareToProperty === "number") {
                        return val < compareToProperty;
                    }

                    const compareTo = model[compareToProperty];
                    if (this.isValueEmpty(val) || this.isValueEmpty(compareTo)) {
                        return true;
                    }

                    if (typeof val !== typeof model[compareToProperty]) {
                        throw Error("Comparing diffrent types");
                    }

                    if (typeof val === "number" || typeof val === "bigint" || typeof val === "boolean") {
                        return val < compareTo;
                    } else if (typeof val === "string") {
                        return val < compareTo;
                    } else if (moment.isMoment(val) && moment.isMoment(compareTo)) {
                        return val.isBefore(compareTo);
                    } else if (isDate(val) && isDate(compareTo)) {
                        return moment(val).isBefore(moment(compareTo));
                    }

                    return false;
                },
                (_val, model) => {
                    if (typeof compareToProperty === "number") {
                        return tValidation(validation.lessThan, { value: compareToProperty?.toString() });
                    }

                    const compareTo = model[compareToProperty];
                    if (moment.isMoment(compareTo)) {
                        return tValidation(validation.lessDateThan, { date: compareTo.format("YYYY-MM-DD HH:mm") });
                    } else if (isDate(compareTo)) {
                        return tValidation(validation.lessDateThan, { date: dateToString(compareTo) });
                    }

                    return tValidation(validation.lessThan, { value: compareTo?.toString() });
                }
            );
        };

        const extendedValidators = {
            greaterThanOrEqualTo,
            greaterThan,
            lessThanOrEqualTo,
            lessThan,
        } as unknown as ExtendedValidators<TModel, TValue>;

        return { ...baseResult, ...extendedValidators };
    };

    ruleFor = <TPropertyName extends keyof TModel, TValue extends TModel[TPropertyName]>(
        propertyName: TPropertyName
    ): ExtendedRuleValidators<TModel, TValue> => {
        const base = this.baseValidator.ruleFor2<TPropertyName, TValue>(propertyName);

        return this.extendRuleValidators<TPropertyName, TValue>(propertyName, base);
    };

    ruleForEach = <
        TArrayPropertyName extends ArrayPropertyNames<TModel>,
        TValue extends TModel[TArrayPropertyName] extends Array<infer V> ? V : TModel[TArrayPropertyName],
        TValueBase extends TModel[TArrayPropertyName] extends (infer TEachValue)[] | readonly (infer TEachValue)[] | null | undefined
            ? TModel[TArrayPropertyName] & (TEachValue[] | readonly TEachValue[] | null | undefined)
            : never,
    >(
        propertyName: TArrayPropertyName
    ): ExtendedRuleValidators<TModel, TValue> => {
        const base = this.baseValidator.ruleForEach2<TArrayPropertyName, TValueBase>(
            propertyName as unknown as TModel[TArrayPropertyName] extends unknown[] | readonly unknown[] | null | undefined
                ? TArrayPropertyName
                : never
        );

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.extendRuleValidators(propertyName, base as unknown as any) as unknown as ExtendedRuleValidators<TModel, TValue>;
    };

    //Extend RuleValidatorsAndExtensions (ExtendedRuleValidatorsAndExtensions)
    private extendValidationResult<TPropertyName extends keyof TModel, TValue extends TModel[TPropertyName]>(
        propertyName: TPropertyName,
        baseResult: BaseValueValidators<TModel, TValue>,
        extendedValidators: ExtendedValidators<TModel, TValue>,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        validatorRule: RuleValidators<TModel, any> & {
            when: (
                condition: (model: TModel) => boolean,
                appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator"
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ) => RuleValidators<TModel, any>;
            unless: (
                condition: (model: TModel) => boolean,
                appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator"
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ) => RuleValidators<TModel, any>;
        }
    ) {
        const { when, unless } = validatorRule;

        const conditionals = {
            when: (condition: (model: TModel) => boolean, appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator") =>
                this.extendRuleValidators(propertyName, when(condition, appliesTo)),
            unless: (condition: (model: TModel) => boolean, appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator") =>
                this.extendRuleValidators(propertyName, unless(condition, appliesTo)),
            whenEmpty: (property?: (keyof TModel)[] | keyof TModel, appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator") =>
                this.extendRuleValidators(
                    propertyName,
                    when(
                        (model: TModel) =>
                            Array.isArray(property)
                                ? property.every((p) => this.isValueEmpty(model[p]))
                                : property !== undefined
                                ? this.isValueEmpty(model[property])
                                : this.isValueEmpty(model[propertyName]),
                        appliesTo
                    )
                ),
            whenNotEmpty: (property?: (keyof TModel)[] | keyof TModel, appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator") =>
                this.extendRuleValidators(
                    propertyName,
                    when(
                        (model: TModel) =>
                            Array.isArray(property)
                                ? property.every((p) => !this.isValueEmpty(model[p]))
                                : property !== undefined
                                ? !this.isValueEmpty(model[property])
                                : !this.isValueEmpty(model[propertyName]),
                        appliesTo
                    )
                ),
        };

        return {
            ...baseResult,
            ...conditionals,
            ...extendedValidators,
        };
    }

    //Extend RuleValidatorsAndConditionExtensions (ExtendedRuleValidatorsAndConditionExtensions)
    private getConditionalsWithMessage<
        TPropertyName extends keyof TModel,
        TValue extends
            | TModel[TPropertyName]
            | (TModel[TPropertyName] & (string | null | undefined))
            | (TModel[TPropertyName] & (number | null | undefined))
            | (TModel[TPropertyName] & (object | null | undefined)),
    >(
        propertyName: TPropertyName,
        baseResult: BaseValueValidators<TModel, TValue>,
        extendedValidators: ExtendedValidators<TModel, TValue>,
        when: (
            condition: (model: TModel) => boolean,
            appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator" | undefined
        ) => RuleValidators<TModel, TValue>,
        unless: (
            condition: (model: TModel) => boolean,
            appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator" | undefined
        ) => RuleValidators<TModel, TValue>,
        withMessage: (message: string) => RuleValidators<TModel, TValue> & {
            when: (
                condition: (model: TModel) => boolean,
                appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator" | undefined
            ) => RuleValidators<TModel, TValue>;
            unless: (
                condition: (model: TModel) => boolean,
                appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator" | undefined
            ) => RuleValidators<TModel, TValue>;
        }
    ) {
        const conditionals = {
            when: (condition: (model: TModel) => boolean, appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator") => {
                when(condition, appliesTo);
                return { ...baseResult, ...extendedValidators };
            },
            unless: (condition: (model: TModel) => boolean, appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator") => {
                unless(condition, appliesTo);
                return { ...baseResult, ...extendedValidators };
            },
            whenEmpty: (property?: (keyof TModel)[] | keyof TModel, appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator") => {
                when(
                    (model: TModel) =>
                        Array.isArray(property)
                            ? property.every((p) => isEmpty(model[p]))
                            : property !== undefined
                            ? isEmpty(model[property])
                            : isEmpty(model[propertyName]),
                    appliesTo
                );
                return { ...baseResult, ...extendedValidators };
            },
            whenNotEmpty: (property?: (keyof TModel)[] | keyof TModel, appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator") => {
                when(
                    (model: TModel) =>
                        Array.isArray(property)
                            ? property.every((p) => !isEmpty(model[p]))
                            : property !== undefined
                            ? !isEmpty(model[property])
                            : !isEmpty(model[propertyName]),
                    appliesTo
                );
                return { ...baseResult, ...extendedValidators };
            },
        };

        return {
            ...conditionals,
            withMessage: (message: string) => this.extendValidationResult(propertyName, baseResult, extendedValidators, withMessage(message)),
        };
    }
}
