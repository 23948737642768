import { types } from "./types";

const initialState = {};

export default (state = initialState, { type, payload, meta }) => {
    switch (type) {
        case types.initDiscountForm:
            return initialState;
        case types.getDiscount.request:
            return { ...initialState, isLoading: true };
        case types.getDiscount.success:
            return {
                ...state,
                ...payload,
                id: meta.isCopy ? "" : payload.id,
                isLoading: false,
            };
        case types.getDiscount.failure:
            return { ...payload, isLoading: false };
        case types.createOrUpdateDiscount.request:
            return { ...state, isSubbmitRequested: true };
        case types.createOrUpdateDiscount.success:
        case types.createOrUpdateDiscount.failure:
            return { ...state, isSubbmitRequested: false };
        case types.getDiscountValidation.request:
            return { ...state };
        case types.getDiscountValidation.success:
            return { ...state, discountValidation: payload };
        case types.getDiscountValidation.failure:
            return { ...state, discountValidation: {} };
        default:
            return state;
    }
};
