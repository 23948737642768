import { generatePath } from "react-router-dom";
import { RoleInfo, UserInfo } from "Identity";
import { useGetCacheQuery } from "api/hooks";
import { Role } from "routing-ts/roles";

const queryKey = "api/identity/user-infos/";

const url = (role?: string) => generatePath(`${queryKey}:role?`, { role: role ? role : null });

export const useGetUsersWithRole = (role?: Role) => {
    return useGetCacheQuery<RoleInfo>({
        app: "identity",
        url: url(role),
        shouldMakeRequest: Boolean(role),
        cacheTime: 60,
    });
};

export const useGetAllUsers = () => {
    return useGetCacheQuery<UserInfo[]>({
        app: "identity",
        url: url(),
    })
}
