import React, { ComponentType, FC, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { signinRedirectKey } from "utils-ts/auth/userManager";

export const AuthenticationGuard = ({ component }: { component: ComponentType<{}> }) => {
    const Component = withAuth(component);

    return <Component />;
};

const withAuth =
    <P extends object>(Component: React.ComponentType<P>): React.FC<P> =>
    (props) => {
        const { isAuthenticated, isLoading, user, signinRedirect } = useAuth();
        let expired = false;
        if (user) {
            const jwtPayload = JSON.parse(window.atob(user.access_token.split(".")[1]));
            expired = jwtPayload.exp * 1000 < Date.now();
        }

        useEffect(() => {
            if (isLoading || (isAuthenticated && !expired)) {
                return;
            }

            (async (): Promise<void> => {
                if (window.location.pathname.includes("callback")) {
                    if (!localStorage.getItem(signinRedirectKey)) {
                        localStorage.setItem(signinRedirectKey, "/");
                    }
                } else {
                    localStorage.setItem(signinRedirectKey, window.location.pathname);
                }

                await signinRedirect();
            })();
        }, [isLoading, isAuthenticated]);

        return isAuthenticated ? <Component {...props} /> : <div className="page-layout">Loading...</div>;
    };
