import React, { ReactNode, useCallback, useMemo, useRef } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList } from "react-window";
import { Grid } from "@mui/material";
import { useWindowSize } from "utils-ts/hooks";
import { SpinningPreloader } from "components-ts/preloaders";
import { VirtualizedParentResizeProvider } from "./VirtualizedParentResizeProvider";

type VirtualizedRowData = {
    children: ReactNode[];
    setHeight: (index: number, height: number) => void;
    windowWidth: number;
    getItemHeight: (index: number) => number;
    getItemButtons?: (index: number) => ReactNode;
    getItemDivider?: (index: number) => ReactNode;
};

const VirtualizedRow = React.memo(
    ({ index, style, data }: { index: number; style: React.CSSProperties; data: VirtualizedRowData }) => {
        const { children, setHeight, windowWidth, getItemButtons, getItemDivider, getItemHeight } = data;
        const { height, ...rest } = style;
        const rowRef = useRef<HTMLDivElement | null>(null);
        const isEven = index % 2 === 0;
        const rowHeight = rowRef.current?.getBoundingClientRect().height;
        const currentHeight = getItemHeight(index);
        const resize = () => {
            setTimeout(() => {
                const currentItemHeight = getItemHeight(index);
                if (rowRef.current && currentItemHeight !== rowRef.current.getBoundingClientRect().height) {
                    setHeight(index, rowRef.current.getBoundingClientRect().height);
                }
            }, 100);
        };

        React.useEffect(() => {
            if (rowRef.current && currentHeight !== rowRef.current.getBoundingClientRect().height) {
                setHeight(index, rowRef.current.getBoundingClientRect().height);
            }
        }, [index, windowWidth, rowHeight]);

        return (
            <VirtualizedParentResizeProvider resize={resize}>
                <Grid
                    ref={rowRef}
                    style={{
                        ...rest,
                        height: currentHeight ? undefined : height,
                        backgroundColor: isEven ? "rgba(0, 0, 255, .1)" : "transparent",
                    }}
                    container
                    item
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                >
                    {children[index]}
                    {getItemButtons !== undefined && <div style={{ position: "absolute", right: "10px", top: "10px" }}>{getItemButtons(index)}</div>}
                    {getItemDivider !== undefined && getItemDivider(index)}
                </Grid>
            </VirtualizedParentResizeProvider>
        );
    },
    (prev, next) =>
        prev.index === next.index &&
        prev.data.windowWidth === next.data.windowWidth &&
        prev.style.top === next.style.top &&
        prev.style.height === next.style.height &&
        prev.data.getItemHeight(prev.index) === next.data.getItemHeight(next.index)
);

type VirtualizedListProps = {
    children: ReactNode[];
} & (
    | {
          itemHasButtons: boolean;
          getItemButtons: (index: number) => ReactNode;
      }
    | {
          itemHasButtons?: never;
          getItemButtons?: never;
      }
) &
    (
        | {
              hideDivider: boolean;
              getItemDivider: (index: number) => ReactNode;
          }
        | {
              hideDivider?: never;
              getItemDivider?: never;
          }
    );

const VirtualizedList: React.FC<VirtualizedListProps> = ({ children, itemHasButtons, getItemButtons, hideDivider, getItemDivider }) => {
    const [windowWidth, windowHeight] = useWindowSize();
    const listRef = useRef<VariableSizeList<VirtualizedRowData> | null>(null);
    const heightMap = useRef<Record<number, number>>({});
    const setHeight = useCallback((index: number, height: number) => {
        heightMap.current = { ...heightMap.current, [index]: height };
        listRef.current?.resetAfterIndex(index);
    }, []);
    const getItemSize = (index: number) => (heightMap.current && heightMap.current[index] ? heightMap.current[index] : 100);

    return (
        <Grid
            item
            style={{
                flex: "1 1 auto",
                height: children.length > 0 ? "95vh" : "50px",
                width: "100%",
            }}
        >
            <AutoSizer disableWidth>
                {({ height }: { height?: number | undefined }) => {
                    if (height === undefined) {
                        return <SpinningPreloader />;
                    }

                    return (
                        <VariableSizeList<VirtualizedRowData>
                            ref={listRef}
                            height={height}
                            itemCount={children.length}
                            estimatedItemSize={height}
                            width="100%"
                            itemSize={getItemSize}
                            itemData={{
                                children,
                                setHeight,
                                windowWidth,
                                getItemHeight: getItemSize,
                                getItemButtons: itemHasButtons ? getItemButtons : undefined,
                                getItemDivider: hideDivider ? undefined : getItemDivider,
                            }}
                            overscanCount={5}
                        >
                            {VirtualizedRow}
                        </VariableSizeList>
                    );
                }}
            </AutoSizer>
        </Grid>
    );
};

export default VirtualizedList;
